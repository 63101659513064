import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavLinkContainer } from './SidebarNavLink.styles';

const SidebarNavLink = (props) => {
  const { children, path: url } = props;
  const { pathname } = useLocation();
  const isActivePath = (path) => pathname.match(path);

  return (
    <NavLinkContainer>
      <Link
        className={`nav-link${isActivePath(url) ? ' nav-link-active' : ''}`}
        to={url}
      >
        {children}
      </Link>
    </NavLinkContainer>
  );
};

export default SidebarNavLink;
