import React, { useEffect } from 'react';
import Header from 'components/Header/Header';
import PromoCodeCard from 'components/AffiliateDashboard/PromoCodeCard';
import {
  Page,
  Referrals,
  Header as ReferralsHeader,
} from './AffiliateDashboard.styles';
import {
  getPromoCode,
  getReferrals,
  setSelectedMonth,
} from 'store/affiliate-dashboard/affiliateDashboardSlice';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'components/Shared/Spinner';
import DropdownMenu from 'components/Shared/DropdownMenu';
import ReferralTable from 'components/AffiliateDashboard/ReferralTable';
import moment from 'moment';

const getUniqueMonths = (data, dateKey) => {
  const months = data.map((row) => moment(row[dateKey]).format('MMM - YYYY'));
  const uniqueMonths = Array.from(new Set(months));
  return uniqueMonths;
};

const AffiliateDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, promoCode, referrals, selectedMonth } = useSelector(
    (state) => state.affiliateDashboard
  );

  // Table
  const headers = [
    {
      name: 'Transaction ID',
      key: 'TransactionID',
    },
    {
      name: 'Parent Email',
      key: 'ParentEmail',
    },
    {
      name: 'Purchase Date',
      key: 'PurchaseDate',
      type: 'date',
    },
    {
      name: 'Plan',
      key: 'Plan',
    },
    {
      name: 'Currency',
      key: 'Currency',
    },
    {
      name: 'Amount',
      key: 'PurchaseAmount',
      type: 'currency',
    },
  ];

  const months = getUniqueMonths(referrals, 'PurchaseDate').reverse();

  const onChangeMonth = (value) => {
    dispatch(setSelectedMonth(value));
  };

  useEffect(() => {
    dispatch(getPromoCode());
    dispatch(getReferrals());
  }, [dispatch]);
  return (
    <div>
      <Header />
      <Page>
        <div className="welcome-text">
          Welcome! <span>Affiliate</span>
        </div>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <PromoCodeCard code={promoCode} />
            <Referrals>
              <ReferralsHeader>
                <h4>My Referrals</h4>
                {referrals.length > 0 && (
                  <DropdownMenu
                    className="dropdown"
                    selectedValue={selectedMonth}
                    valueKey={null}
                    values={months}
                    setValue={onChangeMonth}
                    width="160px"
                  />
                )}
              </ReferralsHeader>
              <ReferralTable
                headers={headers}
                data={referrals.filter(
                  (row) =>
                    moment(row.PurchaseDate).format('MMM - YYYY') ===
                    selectedMonth
                )}
              />
            </Referrals>
          </>
        )}
      </Page>
    </div>
  );
};

export default AffiliateDashboard;
