import styled from 'styled-components';

export const ViewAccountsContainer = styled.div`
  .top-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    background-color: #ffffff;
    border-bottom: solid 1px #cfcfcf;
    position: fixed;
    width: calc(100vw - 195px);
    .back-btn {
      position: absolute;
      left: 40px;
      border: none;
      background: none;
    }
    p {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const TableContainer = styled.div`
  padding: 110px 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .table {
    thead {
      th {
        background-color: #fedd9c;
        border-color: #fec759;
      }
    }
    tbody {
      td {
        border-color: #fec759;
      }
      tr:nth-child(odd) {
        background-color: #fffcf6;
      }
      tr:nth-child(even) {
        background-color: #ffffff;
      }
    }
  }
`;
