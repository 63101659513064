import styled from 'styled-components';
import { primaryButton, secondaryButton } from 'assets/styles/buttons.styles';

export const PreviewCSVContainer = styled.div`
  .top-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    background-color: #ffffff;
    border-bottom: solid 1px #cfcfcf;
    position: fixed;
    width: calc(100vw - 195px);
    p {
      font-size: 18px;
      font-weight: bold;
    }
    .validate-btn {
      min-width: 85px;
    }
    .validate-btn,
    .submit-btn {
      ${primaryButton}
      border-radius: 2px;
      padding: 0px 20px;
      margin: 0px 20px;
    }
  }
  .cancel-btn {
    ${secondaryButton}
    border-radius: 3px;
    padding: 0px 20px;
    margin: 0px 20px;
  }
  .preview {
    padding: 0px 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .table {
    thead {
      th {
        background-color: #fedd9c;
        border-color: #fec759;
      }
    }
    tbody {
      .error-cell {
        font-size: 1rem;
        color: #f25008;
        max-width: 10rem;
        p {
          font-size: 1rem;
          font-weight: 100;
          .badge {
            margin-right: 0.25rem;
          }
        }
      }
      td {
        border-color: #fec759;
      }
      tr:nth-child(odd) {
        background-color: #fffcf6;
      }
      tr:nth-child(even) {
        background-color: #ffffff;
      }
    }
  }
`;

export const ResetPassword = styled.div`
  padding-top: 110px;
  padding-right: 2rem;
  display: flex;
  visibility: ${(props) => (props.isRenewAccount === true ? 'auto' : 'hidden')};
  justify-content: flex-end;

  .container-checkbox {
    display: flex;
    padding: 2px;
    justify-content: center;
    margin-right: 1rem;
  }

  //hide the browser's default check-box
  .container-checkbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    margin-top: 4px;
  }

  //create a custom checkbox
  .container-checkbox .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid #9e9e9e;
    background-color: white;
  }

  //on mouse hover
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ffc400;
    border: none;
  }

  //when checkbox is checked
  .container-checkbox input:checked ~ .checkmark {
    background-color: #ef7836;
    border: none;
  }

  //create the checkmark/indicator (hidden when not checked)
  .container-checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  //show the checkmark when checked
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  //style the checkmark/indicator
  .container-checkbox .checkmark:after {
    left: 7px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .check-box {
    position: inherit;
  }
`;
