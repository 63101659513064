import styled from 'styled-components';
import { primaryButton } from 'assets/styles/buttons.styles';

const RaisePOContainer = styled.div`
  display: flex;
  background: white;
  padding: 20px;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  button {
    ${primaryButton}
    font-size: 16px;
    border-radius: 2px;
    padding: 5px 10px;
    margin-left: 10px;
  }
`;

export default RaisePOContainer;
