import styled from 'styled-components';

export const UploadCSVContainer = styled.div`
  display: flex;
  flex-direction: column;
  .top-section {
    display: flex;
    background: white;
    padding: 20px 50px;
    justify-content: left;
    align-items: center;
    .back-btn {
      border: none;
      background: none;
      font-size: 20px;
      margin-right: 180px;
      outline: none;
      @media only screen and (max-width: 1366px) {
        margin-right: 105px;
      }
      @media only screen and (max-width: 1024px) {
        margin-right: 24px;
      }
    }
    p {
      font-size: 18px;
      font-weight: bold;
      margin: 5px 10px;
    }
  }
  .renewal-csv {
    color: #f25008;
    width: 20%;
    margin: 0;
    font-size: 14px;
    position: relative;
    left: 50rem;
    @media only screen and (max-width: 1366px) {
      left: 46.3rem;
    }
    @media only screen and (max-width: 1024px) {
      left: 27rem;
    }
  }
  .instruction-container {
    border-top: 1px solid #cfcfcf;
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
    }
    p {
      width: 60%;
      text-align: left;
      font-size: 13px;
    }
    .video {
      width: 606px;
      height: 300px;
    }
  }
`;

export const ToastContainer = styled.div`
  position: absolute;
  top: 4rem;
  right: 1rem;
  z-index: 10;

  .toast-title {
    color: #f25008;
  }
`;
