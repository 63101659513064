import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchCustomersByYear,
  fetchAcademicYears,
  fetchNewCustomersByYear,
  fetchRenewAccountsSummary,
  validateCSV,
  bulkValidateCSV,
  fetchAccountsByContract,
  createAccounts,
  seriesCreateAccounts,
  seriesRenewAccounts,
  exportCreatedAccountstoCSV,
  exportCreatedAccountstoJSON,
  seriesValidate,
} from 'services/accountCreation';
import refreshFetchWrapper from 'services/refreshToken';

const initialState = {
  customers: null,
  contracts: null,
  accountQuotas: null,
  academicYears: null,
  newAccounts: null,
  renewAccounts: null,
  studentCSVValidations: null,
  studentCSV: null,
  studentCSVFilename: 'Select CSV',
  teacherCSVValidations: null,
  teacherCSV: null,
  teacherCSVFilename: 'Select CSV',
  selectedSchool: null,
  selectedContractId: null,
  accountCreationResult: null,
  accountRenewalResult: null,
  exportResult: null,
  isCreationLoading: false,
  isRenewalLoading: false,
  isExportCSVLoading: false,
  isValidationLoading: false,
  isLoading: false,
  error: null,
};

export const getAcademicYears = createAsyncThunk(
  'account-creation/getAcademicYears',
  () => {
    return fetchAcademicYears();
  }
);

export const getCustomersByYear = createAsyncThunk(
  'account-creation/getCustomersByYear',
  (year) => {
    return refreshFetchWrapper(fetchCustomersByYear, year);
  }
);

export const getNewCustomersByYear = createAsyncThunk(
  'account-creation/getNewCustomersByYear',
  (year) => {
    return refreshFetchWrapper(fetchNewCustomersByYear, year);
  }
);

export const getRenewAccountsSummary = createAsyncThunk(
  'account-creation/getRenewAccountsSummary',
  (year) => {
    return refreshFetchWrapper(fetchRenewAccountsSummary, year);
  }
);

export const getAccountQuotaByContractID = createAsyncThunk(
  'account-creation/getAccountQuotaByContractID',
  (contractID) => {
    return refreshFetchWrapper(fetchAccountsByContract, contractID);
  }
);

export const bulkValidateStudentCSV = createAsyncThunk(
  'account-creation/bulkValidateStudentCSV',
  (params) => {
    const queryParams = {
      ...params,
      accountType: 1,
    };
    return seriesValidate(queryParams);
  }
);

export const bulkValidateTeacherCSV = createAsyncThunk(
  'account-creation/bulkValidateTeacherCSV',
  (params) => {
    const queryParams = {
      ...params,
      accountType: 3,
    };
    return seriesValidate(queryParams);
  }
);

export const postValidateStudentCSV = createAsyncThunk(
  'account-creation/validateStudentCSV',
  (params) => {
    const queryParams = {
      ...params,
      accountType: 1,
    };
    return validateCSV(queryParams);
  }
);

export const postValidateTeacherCSV = createAsyncThunk(
  'account-creation/validateTeacherCSV',
  (params) => {
    const queryParams = {
      ...params,
      accountType: 3,
    };
    return validateCSV(queryParams);
  }
);

// Create accounts from an array of multiple contract file IDs
export const bulkCreateAccounts = createAsyncThunk(
  'account-creation/bulkCreateAccounts',
  (contractFileIDs) => {
    return seriesCreateAccounts(contractFileIDs);
  }
);

// Renew accounts from an array of multiple contract file IDs
export const bulkRenewAccounts = createAsyncThunk(
  'account-creation/bulkRenewAccounts',
  (params) => {
    return seriesRenewAccounts(params);
  }
);

export const postCreateAccounts = createAsyncThunk(
  'account-creation/createAccounts',
  (contractFileID) => {
    return createAccounts(contractFileID);
  }
);

export const exportCreatedAccountsCSV = createAsyncThunk(
  'account-creation/exportCreatedAccountsToCSV',
  (params) => {
    return exportCreatedAccountstoCSV(params);
  }
);

export const exportCreatedAccountsJSON = createAsyncThunk(
  'account-creation/exportCreatedAccountsToJSON',
  (params) => {
    return exportCreatedAccountstoJSON(params);
  }
);

const accountCreationSlice = createSlice({
  name: 'account-creation',
  initialState: initialState,
  reducers: {
    resetAccountCreation: (state) => initialState,
    setStudentCSV: (state, action) => {
      state.studentCSV = action.payload;
    },
    setTeacherCSV: (state, action) => {
      state.teacherCSV = action.payload;
    },
    setStudentCSVFilename: (state, action) => {
      state.studentCSVFilename = action.payload;
    },
    setTeacherCSVFilename: (state, action) => {
      state.teacherCSVFilename = action.payload;
    },
    setSelectedContract: (state, action) => {
      state.selectedContractId = action.payload;
    },
    setSelectedSchool: (state, action) => {
      state.selectedSchool = action.payload;
    },
    resetCSVValidations: (state) => {
      state.studentCSVValidations = null;
      state.teacherCSVValidations = null;
    },
    resetStudentCSVFileAndValidations: (state) => {
      state.studentCSV = null;
      state.studentCSVFilename = 'Select CSV';
      state.studentCSVValidations = null;
      state.accountCreationResult = null;
      state.accountRenewalResult = null;
    },
    resetTeacherCSVFileAndValidations: (state) => {
      state.teacherCSV = null;
      state.teacherCSVFilename = 'Select CSV';
      state.teacherCSVValidations = null;
      state.accountCreationResult = null;
      state.accountRenewalResult = null;
    },
  },
  extraReducers: {
    [getCustomersByYear.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getCustomersByYear.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.customers = action.payload.Result;
    },
    [getCustomersByYear.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getAcademicYears.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getAcademicYears.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.academicYears = action.payload.Result;
    },
    [getAcademicYears.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getNewCustomersByYear.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getNewCustomersByYear.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.newAccounts = action.payload;
    },
    [getNewCustomersByYear.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },

    [getRenewAccountsSummary.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getRenewAccountsSummary.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.renewAccounts = action.payload;
    },
    [getRenewAccountsSummary.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getAccountQuotaByContractID.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.accountQuotas = null;
    },
    [getAccountQuotaByContractID.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.accountQuotas = action.payload;
    },
    [getAccountQuotaByContractID.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [postValidateStudentCSV.pending]: (state) => {
      state.isValidationLoading = true;
      state.error = null;
    },
    [postValidateStudentCSV.fulfilled]: (state, action) => {
      state.isValidationLoading = false;
      state.studentCSVValidations = action.payload;
    },
    [postValidateStudentCSV.rejected]: (state, action) => {
      state.isValidationLoading = false;
      state.error = action.error.message;
    },
    [bulkValidateStudentCSV.pending]: (state) => {
      state.isValidationLoading = true;
      state.error = null;
    },
    [bulkValidateStudentCSV.fulfilled]: (state, action) => {
      state.isValidationLoading = false;
      state.studentCSVValidations = action.payload;
    },
    [bulkValidateStudentCSV.rejected]: (state, action) => {
      state.isValidationLoading = false;
      state.error = action.error.message;
    },
    [postValidateTeacherCSV.pending]: (state) => {
      state.isValidationLoading = true;
      state.error = null;
    },
    [postValidateTeacherCSV.fulfilled]: (state, action) => {
      state.isValidationLoading = false;
      state.teacherCSVValidations = action.payload;
    },
    [postValidateTeacherCSV.rejected]: (state, action) => {
      state.isValidationLoading = false;
      state.error = action.error.message;
    },
    [bulkValidateTeacherCSV.pending]: (state) => {
      state.isValidationLoading = true;
      state.error = null;
    },
    [bulkValidateTeacherCSV.fulfilled]: (state, action) => {
      state.isValidationLoading = false;
      state.teacherCSVValidations = action.payload;
    },
    [bulkValidateTeacherCSV.rejected]: (state, action) => {
      state.isValidationLoading = false;
      state.error = action.error.message;
    },
    [postCreateAccounts.pending]: (state) => {
      state.isCreationLoading = true;
      state.error = null;
    },
    [postCreateAccounts.fulfilled]: (state, action) => {
      state.isCreationLoading = false;
      state.accountCreationResult = action.payload;
    },
    [postCreateAccounts.rejected]: (state, action) => {
      state.isCreationLoading = false;
      state.error = action.error.message;
    },
    [bulkCreateAccounts.pending]: (state) => {
      state.isCreationLoading = true;
      state.error = null;
    },
    [bulkCreateAccounts.fulfilled]: (state, action) => {
      state.isCreationLoading = false;
      state.accountCreationResult = action.payload;
    },
    [bulkCreateAccounts.rejected]: (state, action) => {
      state.isCreationLoading = false;
      state.error = action.error.message;
    },
    [bulkRenewAccounts.pending]: (state) => {
      state.isRenewalLoading = true;
      state.error = null;
    },
    [bulkRenewAccounts.fulfilled]: (state, action) => {
      state.isRenewalLoading = false;
      state.accountRenewalResult = action.payload;
    },
    [bulkRenewAccounts.rejected]: (state, action) => {
      state.isRenewalLoading = false;
      state.error = action.error.message;
    },
    [exportCreatedAccountsCSV.pending]: (state) => {
      state.isExportCSVLoading = true;
      state.exportResult = null;
      state.error = null;
    },
    [exportCreatedAccountsCSV.fulfilled]: (state, action) => {
      state.isExportCSVLoading = false;
      state.exportResult = action.payload;
    },
    [exportCreatedAccountsCSV.rejected]: (state, action) => {
      state.isExportCSVLoading = false;
      state.error = action.error.message;
    },
    [exportCreatedAccountsJSON.pending]: (state) => {
      state.isExportCSVLoading = true;
      state.exportResult = null;
      state.error = null;
    },
    [exportCreatedAccountsJSON.fulfilled]: (state, action) => {
      state.isExportCSVLoading = false;
      state.exportResult = action.payload;
    },
    [exportCreatedAccountsJSON.rejected]: (state, action) => {
      state.isExportCSVLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = accountCreationSlice;
export const {
  resetAccountCreation,
  setStudentCSV,
  setTeacherCSV,
  setStudentCSVFilename,
  setTeacherCSVFilename,
  setSelectedContract,
  setSelectedSchool,
  resetCSVValidations,
  resetStudentCSVFileAndValidations,
  resetTeacherCSVFileAndValidations,
} = actions;
export default reducer;
