import React, { useState } from 'react';
import CSVReader from 'react-csv-reader';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  setStudentCSV,
  setTeacherCSV,
  setStudentCSVFilename,
  setTeacherCSVFilename,
  exportCreatedAccountsJSON,
} from 'store/account-creation/accountCreationSlice';
import { NumberIndicatorContainer } from './NumberIndicator.styles';
import { useHistory } from 'react-router-dom';
import { checkAccountQuota, checkCSVHeaders } from 'helpers/csvUpload';
import { fetchAccountQuotaByContractIDAccountType } from 'services/accountCreation';

// Helper
const getAccountQuota = (data, type) => {
  const accountTypeId = type === 'student' ? 1 : 3;
  const contractDetails = data.ContractDetails;
  const lineItemIndex = contractDetails.findIndex(
    (lineItem) =>
      lineItem.AccountType === accountTypeId && lineItem.ItemType === 'Account'
  );
  const result = {
    accPurchased: 0,
    accCreated: 0,
    accRemaining: 0,
  };
  if (lineItemIndex !== -1) {
    result.accPurchased = contractDetails[lineItemIndex].AccountsPurchased;
    result.accCreated = contractDetails[lineItemIndex].AccountsConsumed;
    result.accRemaining = contractDetails[lineItemIndex].AccountsRemaining;
    return result;
  } else {
    return result;
  }
};

const NumberIndicator = ({
  type,
  setCSVErrors,
  setShowToast,
  data,
  selectedContractId,
  isRenewal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { studentCSVFilename, teacherCSVFilename } = useSelector(
    (state) => state.accountCreation
  );
  const renewAccount =
    history.location.pathname === '/manage-school/renew-account/upload-csv';

  const accountQuota = data !== null ? getAccountQuota(data, type) : null;
  // Component States
  const [CSVData, setCSVData] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(
    studentCSVFilename !== 'Select CSV' ? false : true
  );
  const [showSelectCSV, setShowSelectCSV] = useState(
    accountQuota !== null
      ? accountQuota.accCreated !== 0
        ? false
        : true
      : false
  );

  // Event Handlers
  const handleStudentFileLoaded = async (data, fileInfo) => {
    const errors = [];
    const headersValidation = checkCSVHeaders('student', data, !isRenewal);
    const apiValidation = await fetchAccountQuotaByContractIDAccountType({
      contractID: selectedContractId,
      accountType: 1,
    });
    let quotaValidation;
    if (apiValidation.hasOwnProperty('ContractDetails')) {
      const apiAccountQuota = getAccountQuota(apiValidation, 'student');
      quotaValidation = checkAccountQuota(
        apiAccountQuota.accRemaining,
        data.length
      );
    } else {
      quotaValidation = checkAccountQuota(
        accountQuota.accRemaining,
        data.length
      );
    }
    if (headersValidation.pass === false) {
      errors.push(headersValidation);
    } else {
      errors.push({
        pass: true,
      });
    }
    if (quotaValidation === false) {
      errors.push({
        pass: false,
      });
    } else {
      errors.push({
        pass: true,
      });
    }
    dispatch(setStudentCSVFilename(fileInfo.name));
    setCSVData(data);
    if (headersValidation.pass && quotaValidation) {
      setIsUploadDisabled(false);
    } else {
      setCSVErrors(errors);
      setIsUploadDisabled(true);
      setShowToast(true);
    }
  };
  const handleTeacherFileLoaded = async (data, fileInfo) => {
    const errors = [];
    const headersValidation = checkCSVHeaders('teacher', data, !isRenewal);
    const apiValidation = await fetchAccountQuotaByContractIDAccountType({
      contractID: selectedContractId,
      accountType: 3,
    });
    let quotaValidation;
    if (apiValidation.hasOwnProperty('ContractDetails')) {
      const apiAccountQuota = getAccountQuota(apiValidation, 'teacher');
      quotaValidation = checkAccountQuota(
        apiAccountQuota.accRemaining,
        data.length
      );
    } else {
      quotaValidation = checkAccountQuota(
        accountQuota.accRemaining,
        data.length
      );
    }
    if (headersValidation.pass === false) {
      errors.push(headersValidation);
    } else {
      errors.push({
        pass: true,
      });
    }
    if (quotaValidation === false) {
      errors.push({
        pass: false,
      });
    } else {
      errors.push({
        pass: true,
      });
    }
    dispatch(setTeacherCSVFilename(fileInfo.name));
    setCSVData(data);
    if (headersValidation.pass && quotaValidation) {
      setIsUploadDisabled(false);
    } else {
      setCSVErrors(errors);
      setIsUploadDisabled(true);
      setShowToast(true);
    }
  };
  const handleError = (err) => console.log(err);

  const removeStudentCSV = () => {
    setCSVData([]);
    dispatch(setStudentCSVFilename('Select CSV'));
    setIsUploadDisabled(true);
  };

  const removeTeacherCSV = () => {
    setCSVData([]);
    dispatch(setTeacherCSVFilename('Select CSV'));
    setIsUploadDisabled(true);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, '_'),
    transform: (value) => value.trim(),
  };
  return (
    <NumberIndicatorContainer>
      {accountQuota && (
        <>
          <div className="indicator-label">
            Total number of {type === 'student' ? 'students ' : 'teachers '}
            <div className="purchased-number">
              {`(Purchased total: ${accountQuota.accPurchased} ${
                type === 'student' ? 'students' : 'teachers'
              })`}
            </div>
          </div>
          {type === 'student' && (
            <>
              <div className="status-container">
                <p className="total-number">{accountQuota.accCreated}</p>
                {!showSelectCSV && accountQuota.accCreated > 0 && (
                  <>
                    <button
                      className="view-btn"
                      onClick={() => {
                        dispatch(
                          exportCreatedAccountsJSON({
                            contractID: selectedContractId,
                            accountType: 1,
                            pageIndex: 0,
                            pageSize: accountQuota.accCreated,
                          })
                        );
                        history.push(
                          renewAccount
                            ? '/manage-school/renew-account/view-accounts/1'
                            : '/manage-school/create-account/view-accounts/1'
                        );
                      }}
                    >
                      View
                    </button>
                    <div className="status">
                      <p>
                        {accountQuota.accRemaining === 0
                          ? 'Completed'
                          : 'Uploaded'}
                      </p>
                      <span>
                        Created on{' '}
                        {moment(accountQuota.UpdatedDate).format('DD MMM YYYY')}
                      </span>
                    </div>
                    {accountQuota.accRemaining > 0 && (
                      <button
                        onClick={() => {
                          setShowSelectCSV(true);
                        }}
                      >
                        Add more
                      </button>
                    )}
                  </>
                )}
                {showSelectCSV && accountQuota.accRemaining > 0 && (
                  <div className="button-group">
                    <CSVReader
                      cssClass="react-csv-input"
                      label={studentCSVFilename}
                      onFileLoaded={handleStudentFileLoaded}
                      onError={handleError}
                      parserOptions={papaparseOptions}
                      inputId="student-csv"
                    />
                    {studentCSVFilename !== 'Select CSV' ? (
                      <span className="remove-btn" onClick={removeStudentCSV}>
                        X
                      </span>
                    ) : (
                      ''
                    )}
                    <button
                      onClick={() => {
                        if (CSVData.length > 0) {
                          dispatch(setStudentCSV(CSVData));
                        }
                        history.push(
                          renewAccount
                            ? '/manage-school/renew-account/upload-csv/student-preview'
                            : '/manage-school/create-account/upload-csv/student-preview'
                        );
                      }}
                      disabled={isUploadDisabled}
                    >
                      Upload
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
          {type === 'teacher' && (
            <>
              <div className="status-container">
                <p className="total-number">{accountQuota.accCreated}</p>
                {!showSelectCSV && accountQuota.accCreated !== 0 && (
                  <>
                    <button
                      className="view-btn"
                      onClick={() => {
                        dispatch(
                          exportCreatedAccountsJSON({
                            contractID: selectedContractId,
                            accountType: 3,
                            pageIndex: 0,
                            pageSize: accountQuota.accCreated,
                          })
                        );
                        history.push(
                          renewAccount
                            ? '/manage-school/renew-account/view-accounts/3'
                            : '/manage-school/create-account/view-accounts/3'
                        );
                      }}
                    >
                      View
                    </button>
                    <div className="status">
                      <p>
                        {accountQuota.accRemaining === 0
                          ? 'Completed'
                          : 'Uploaded'}
                      </p>
                      <span>
                        Created on{' '}
                        {moment(accountQuota.UpdatedDate).format('DD MMM YYYY')}
                      </span>
                    </div>
                    {accountQuota.accRemaining > 0 && (
                      <button
                        onClick={() => {
                          setShowSelectCSV(true);
                        }}
                      >
                        Add more
                      </button>
                    )}
                  </>
                )}
                {showSelectCSV && accountQuota.accRemaining > 0 && (
                  <div className="button-group">
                    <CSVReader
                      cssClass="react-csv-input"
                      label={teacherCSVFilename}
                      onFileLoaded={handleTeacherFileLoaded}
                      onError={handleError}
                      parserOptions={papaparseOptions}
                      inputId="teacher-csv"
                    />
                    {teacherCSVFilename !== 'Select CSV' ? (
                      <span className="remove-btn" onClick={removeTeacherCSV}>
                        X
                      </span>
                    ) : (
                      ''
                    )}
                    <button
                      onClick={() => {
                        if (CSVData.length > 0) {
                          dispatch(setTeacherCSV(CSVData));
                        }
                        history.push(
                          renewAccount
                            ? '/manage-school/renew-account/upload-csv/teacher-preview'
                            : '/manage-school/create-account/upload-csv/teacher-preview'
                        );
                      }}
                      disabled={isUploadDisabled}
                    >
                      Upload
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </NumberIndicatorContainer>
  );
};

export default NumberIndicator;
