import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import loginRequest from 'services/login';

const initialState = {
  user: null,
  isLoading: false,
  isAffiliate: false,
  error: null,
};

const affiliateRoleIDChecker = (roles) => {
  const affiliateRoleIndex = roles.findIndex((roles) => roles.ID === 100);
  if (affiliateRoleIndex !== -1) {
    return true;
  } else {
    return false;
  }
};

export const getToken = createAsyncThunk('login/getToken', (params) => {
  const { username, password } = params;
  return loginRequest(username, password);
});

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    resetLogin: (state) => initialState,
  },
  extraReducers: {
    [getToken.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getToken.fulfilled]: (state, action) => {
      localStorage.setItem('access_token', action.payload.access_token);
      localStorage.setItem('refresh_token', action.payload.refresh_token);
      state.user = action.payload;
      state.isLoading = false;
      state.isAffiliate = affiliateRoleIDChecker(
        JSON.parse(action.payload.role)
      );
    },
    [getToken.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = loginSlice;
export const { resetLogin } = actions;
export default reducer;
