import React from 'react';
import { SidebarContainer } from './Sidebar.styles';

const Sidebar = (props) => {
  const { children, className, expanded, toggleNavigation } = props;
  return (
    <SidebarContainer>
      <button
        className={
          expanded
            ? 'navbar-toggler sidebar-toggler sidebar-open'
            : 'navbar-toggler sidebar-toggler'
        }
        type="button"
        onClick={toggleNavigation}
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className={
          expanded
            ? `sidebar sidebar-open ${className || ''}`
            : `sidebar ${className || ''}`
        }
      >
        {children}
      </div>
    </SidebarContainer>
  );
};

export default Sidebar;
