import React from 'react';
import moment from 'moment';
import { ReferralTableContainer } from './ReferralTable.styles';

// Helper
const formatValue = (value, type) => {
  if (type === 'date') {
    return moment(value).format('DD-MM-YYYY hh:mm a');
  } else if (type === 'currency') {
    return value.toFixed(2);
  } else {
    if (value) {
      return value;
    } else {
      return '-';
    }
  }
};

const ReferralTable = ({ headers, data }) => {
  return (
    <ReferralTableContainer>
      <table className=" table-responsive-md table table-bordered">
        <thead>
          <tr>
            <th scope="col"></th>
            {headers.map((header) => (
              <th scope="col" key={header.key}>
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr>
              <td>{index + 1}</td>
              {headers.map((header) => (
                <td>{formatValue(row[header.key], header.type)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ReferralTableContainer>
  );
};

export default ReferralTable;
