import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from 'components/Header/Header';
import Sidebar from 'components/Sidebar/Sidebar';
import SidebarManageSchool from 'components/Sidebar/SidebarManageSchool';
import MySchool from './MySchool';
import CreateAccount from './CreateAccount';
import UploadCSV from './UploadCSV';
import PreviewCSV from './PreviewCSV';
import ViewAccounts from './ViewAccounts';
import RenewAccount from './RenewAccount';
import { ManageSchoolContainer } from './ManageSchool.styles';

const ManageSchool = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Header></Header>
      <main>
        <Sidebar>
          <SidebarManageSchool></SidebarManageSchool>
        </Sidebar>
        <ManageSchoolContainer>
          {pathname === '/manage-school' && <MySchool></MySchool>}
          <Switch>
            <Route
              exact
              path="/manage-school/my-schools"
              component={MySchool}
            />
            <Route
              exact
              path="/manage-school/create-account"
              component={CreateAccount}
            />
            <Route
              exact
              path="/manage-school/create-account/view-accounts/:accountTypeID"
              component={(props) => <ViewAccounts {...props} />}
            />
            <Route
              exact
              path="/manage-school/create-account/upload-csv"
              component={UploadCSV}
            ></Route>
            <Route
              exact
              path="/manage-school/create-account/upload-csv/student-preview"
              component={PreviewCSV}
            ></Route>
            <Route
              exact
              path="/manage-school/create-account/upload-csv/teacher-preview"
              component={PreviewCSV}
            ></Route>
            <Route
              exact
              path="/manage-school/renew-account"
              component={RenewAccount}
            ></Route>
            <Route
              exact
              path="/manage-school/renew-account/view-accounts/:accountTypeID"
              component={(props) => <ViewAccounts {...props} />}
            />
            <Route
              exact
              path="/manage-school/renew-account/upload-csv"
              component={UploadCSV}
            ></Route>
            <Route
              exact
              path="/manage-school/renew-account/upload-csv/student-preview"
              component={PreviewCSV}
            ></Route>
            <Route
              exact
              path="/manage-school/renew-account/upload-csv/teacher-preview"
              component={PreviewCSV}
            ></Route>
          </Switch>
        </ManageSchoolContainer>
      </main>
    </>
  );
};

export default ManageSchool;
