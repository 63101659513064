import React from 'react';
import RaisePOContainer from './RaisePO.styles';

const RaisePO = () => {
  return (
    <RaisePOContainer>
      <div>Your newly signed up school is not in the list?</div>
      <button>Raise Purchase Order</button>
    </RaisePOContainer>
  );
};

export default RaisePO;
