import styled from 'styled-components';

const RenewAccountContainer = styled.div`
  .table-label {
    display: flex;
    padding: 20px 50px;
    p {
      margin: 5px 10px 5px 0px;
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export default RenewAccountContainer;
