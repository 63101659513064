import styled from 'styled-components';

export const NavbarCollapseContainer = styled.div`
  display: flex;
  .navbar-nav {
    flex-direction: row;
    padding: 0px 60px;
    @media (max-width: 747px) {
      padding: 0px 10px;
    }
  }
`;
