import styled from 'styled-components';

export const HeaderNav = styled.nav`
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  padding: 0px 30px;
  justify-content: space-between;
  background-color: #4a4947;
  box-shadow: 0px 1px 4px rgba(#000, 0.16);
  z-index: 100;
  align-items: center;
  .navbar-brand {
    display: flex;
    align-items: flex-end;

    img {
      height: 38px;
    }
    .partners-logo {
      color: #ffc400;
      font-size: 16px;
      margin-left: 5px;
      img {
        height: 20px;
        margin-left: 5px;
        margin-bottom: 5px;
      }
    }
  }
`;
