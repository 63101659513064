import styled from 'styled-components';

const RenewAccountTableContainer = styled.div`
  .table-data {
    padding-left: 50px;
    padding-right: 50px;
    .table td,
    .table th {
      padding: 5px;
      vertical-align: middle;
    }
    thead {
      tr {
        background: #fff7e8;
        text-align: center;
      }
    }
    tbody {
      tr {
        text-align: center;
      }
      tr:nth-child(odd) {
        background-color: #ffffff;
      }
    }
    .create th,
    .create td {
      border: 1px solid #ffd98d;
    }
    .student-table {
      margin-bottom: 0px;
      border-bottom: none;
    }
    .teacher-table {
      margin-bottom: 30px;
      .date,
      .country,
      .school-name {
        visibility: hidden;
      }
    }
    .date,
    .country {
      width: 100px;
    }
    .school-name {
      width: 400px;
    }
    .renewed-account {
      width: 250px;
      border-top: none;
    }
    .old-account {
      width: 150px;
      border-top: none;
    }
    .action {
      width: 150px;
      border-top: none;
    }
    button {
      border: none;
      background-color: white;
      font-size: 16px;
      color: #f25008;
      padding: 5px 10px;
      outline: none;
    }
    .completed-btn {
      color: #2faa58;
    }
    button:hover {
      text-decoration: underline;
    }
  }
  .completed {
    thead {
      tr {
        background: #defedf;
      }
    }
    th,
    td {
      border: 1px solid #b5e6b6;
    }
  }
`;

export default RenewAccountTableContainer;
