import React, { useState } from 'react';
import { Card, ContentBankBtn } from './PromoCodeCard.styles';
import Clipboard from 'react-clipboard.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PromoCodeCard = ({ code }) => {
  const [hasCopied, setHasCopied] = useState(false);
  return (
    <Card hasCopied={hasCopied}>
      <strong className="title">My Promo Code</strong>
      <div className="promocode">
        <span>{code}</span>
        <Clipboard
          component="button"
          button-href="#"
          data-clipboard-text={code}
          onSuccess={() => {
            console.log('Copied');
            setHasCopied(true);
          }}
        >
          {hasCopied ? 'Copied' : 'Copy'}
        </Clipboard>
      </div>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip id="tooltip-left">
            All the marketing assets you need to help you promote KooBits.
          </Tooltip>
        }
      >
        <ContentBankBtn
          onClick={() => {
            window.open(
              'https://sites.google.com/view/koobitscontentbank',
              '_blank'
            );
          }}
        >
          Content Bank
        </ContentBankBtn>
      </OverlayTrigger>
    </Card>
  );
};

export default PromoCodeCard;
