import React, { useState, useEffect } from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import NumberIndicator from 'components/ManageSchool/CreateAccount/NumberIndicator';
import DropdownMenu from 'components/Shared/DropdownMenu';
import { UploadCSVContainer, ToastContainer } from './UploadCSV.styles';
import { useHistory } from 'react-router-dom';
import Video from 'assets/img/video.png';
import { setSelectedContract } from 'store/account-creation/accountCreationSlice';
import { getAccountQuotaByContractID } from 'store/account-creation/accountCreationSlice';

const UploadCSV = () => {
  const dispatch = useDispatch();
  const { selectedContractId, selectedSchool, accountQuotas } = useSelector(
    (state) => state.accountCreation
  );
  const selectedContractDetails = selectedSchool.paidContracts.find(
    (contract) => contract.ContractId === selectedContractId
  );
  const uniqueAcademicYears = selectedSchool.paidContracts
    .map((contract) => contract.AcademicYear)
    .filter((year, i, a) => a.indexOf(year) === i)
    .sort((a, b) => b - a);

  // Component States
  const [year, setYear] = useState(selectedContractDetails.AcademicYear);
  const [studentCSVErrors, setStudentCSVErrors] = useState(null);
  const [teacherCSVErrors, setTeacherCSVErrors] = useState(null);
  const [showStudentToast, setShowStudentToast] = useState(false);
  const [showTeacherToast, setShowTeacherToast] = useState(false);

  const handleSelectYearOption = (year) => {
    setYear(year);
    const getContract = selectedSchool.paidContracts.find(
      (contract) => contract.AcademicYear === year
    );
    dispatch(setSelectedContract(getContract.ContractId));
  };

  const history = useHistory();

  const renewAccount =
    history.location.pathname === '/manage-school/renew-account/upload-csv';

  useEffect(() => {
    if (selectedContractId !== null) {
      dispatch(getAccountQuotaByContractID(selectedContractId));
    }
  }, [dispatch, selectedContractId]);
  return (
    <UploadCSVContainer>
      <div className="top-section">
        <button
          className="back-btn"
          onClick={() =>
            history.push(
              renewAccount
                ? '/manage-school/renew-account'
                : '/manage-school/create-account'
            )
          }
        >
          {'<'}
        </button>
        <p>{selectedSchool.schoolName}</p>
        {renewAccount && (
          <DropdownMenu
            className="dropdown"
            selectedValue={`Year ${year} - ${year + 1}`}
            valueKey={null}
            values={uniqueAcademicYears}
            setValue={handleSelectYearOption}
            width="160px"
          />
        )}
      </div>
      <ToastContainer>
        <Toast
          onClose={() => setShowStudentToast(false)}
          show={showStudentToast}
        >
          <Toast.Header>
            <strong className="mr-auto toast-title">
              CSV File Format Error
            </strong>
          </Toast.Header>
          <Toast.Body>
            {studentCSVErrors && studentCSVErrors[1].pass === false && (
              <p>
                The number of rows in your csv file has exceeded your remaining
                account quota.
              </p>
            )}
            {studentCSVErrors &&
              studentCSVErrors[0].pass === false &&
              'Please check the format of your students csv file.'}
            {studentCSVErrors &&
              studentCSVErrors[0].pass === false &&
              studentCSVErrors[0].missingHeaders.length > 0 && (
                <p>
                  Missing Headers:{' '}
                  {studentCSVErrors[0].missingHeaders.join(', ')}
                </p>
              )}
            {studentCSVErrors &&
              studentCSVErrors[0].pass === false &&
              studentCSVErrors[0].invalidHeaders.length > 0 && (
                <p>
                  Invalid Headers:{' '}
                  {studentCSVErrors[0].invalidHeaders.join(', ')}
                </p>
              )}
          </Toast.Body>
        </Toast>
        <Toast
          onClose={() => setShowTeacherToast(false)}
          show={showTeacherToast}
        >
          <Toast.Header>
            <strong className="mr-auto toast-title">
              CSV File Format Error
            </strong>
          </Toast.Header>
          <Toast.Body>
            {teacherCSVErrors && teacherCSVErrors[1].pass === false && (
              <p>
                The number of rows in your csv file has exceeded your remaining
                account quota.
              </p>
            )}
            {teacherCSVErrors &&
              teacherCSVErrors[0].pass === false &&
              'Please check the format of your teachers csv file.'}

            {teacherCSVErrors &&
              teacherCSVErrors[0].pass === false &&
              teacherCSVErrors[0].missingHeaders.length > 0 && (
                <p>
                  Missing Headers:{' '}
                  {teacherCSVErrors[0].missingHeaders.join(', ')}
                </p>
              )}
            {teacherCSVErrors &&
              teacherCSVErrors[0].pass === false &&
              teacherCSVErrors[0].invalidHeaders.length > 0 && (
                <p>
                  Invalid Headers:{' '}
                  {teacherCSVErrors[0].invalidHeaders.join(', ')}
                </p>
              )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {accountQuotas !== null && (
        <>
          {renewAccount ? (
            <div style={{ background: 'white' }}>
              <p className="renewal-csv">Renewal school CSV</p>
            </div>
          ) : null}
          <NumberIndicator
            type="student"
            setCSVErrors={setStudentCSVErrors}
            setShowToast={setShowStudentToast}
            data={accountQuotas}
            selectedContractId={selectedContractId}
            isRenewal={renewAccount}
          ></NumberIndicator>
          <NumberIndicator
            type="teacher"
            setCSVErrors={setTeacherCSVErrors}
            setShowToast={setShowTeacherToast}
            data={accountQuotas}
            selectedContractId={selectedContractId}
            isRenewal={renewAccount}
          ></NumberIndicator>
        </>
      )}
      <div className="instruction-container">
        {renewAccount ? (
          <h4>Instruction for uploading renewal CSV</h4>
        ) : (
          <h4>Instructions for uploading CSV file</h4>
        )}
        <p>
          To bulk create accounts, you can upload CSV file (which can be opened
          by Microsoft Excel). You can download our CSV templates (
          <a
            href={
              renewAccount
                ? 'https://docs.google.com/spreadsheets/d/1KeLvU9nW9QkkwClwEkGUn0rZYQULC6Q3YgYZEb_O074/edit?usp=sharing'
                : 'https://docs.google.com/spreadsheets/d/1BZUegZGiPPloBZhghI73VDqiUNBfbNgBdw1-Qy6ma5g/edit?usp=sharing'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Students
          </a>
          {` / `}
          <a
            href={
              renewAccount
                ? 'https://docs.google.com/spreadsheets/d/1XC9shUKcZXqIbqU6KOiUnZ49WTtcf1L81ljMp1dEx-k/edit?usp=sharing'
                : 'https://docs.google.com/spreadsheets/d/1A_oLq3X3EXjjcrh1ynsI5neJluu7IScvK-ZJ-dtowMc/edit?usp=sharing'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Teachers
          </a>
          ). Fill it up with your account info and upload using the button
          above.
        </p>
        <div className="video">
          <img src={Video} alt="video"></img>
        </div>
      </div>
    </UploadCSVContainer>
  );
};

export default UploadCSV;
