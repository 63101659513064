export const AccountType = {
  1: 'Student',
  2: 'Student Trial',
  3: 'Teacher',
  4: 'Teacher Trial',
  5: 'Parent',
  6: 'School Admin',
  7: 'Teacher Admin',
};

export const PaymentStatus = {
  1: 'Paid',
  2: 'Not Paid',
};

export const ContractStatus = {
  1: 'New',
  2: 'In Transition',
  3: 'Renewed',
  4: 'Expired',
};

// Maps parsed headers to original CSV template headers
export const CSVHeaders = {
  name: 'Name',
  level: 'Grade Level',
  class_name: 'Class Name',
  login_id: 'Login ID',
  password: 'Password',
  new_existing_user: 'New/Existing User',
};
