import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import MySchools from 'assets/img/SidebarIcon/ManageSchool/icon-my-schools.svg';
import MySchoolsActive from 'assets/img/SidebarIcon/ManageSchool/icon-my-schools-active.svg';
import CreateAccount from 'assets/img/SidebarIcon/ManageSchool/icon-create-account.svg';
import CreateAccountActive from 'assets/img/SidebarIcon/ManageSchool/icon-create-account-active.svg';
import RenewAccount from 'assets/img/SidebarIcon/ManageSchool/icon-renew-account.svg';
import RenewAccountActive from 'assets/img/SidebarIcon/ManageSchool/icon-renew-account-active.svg';
import AlignSyllabus from 'assets/img/SidebarIcon/ManageSchool/icon-align-syllabus.svg';
import AlignSyllabusActive from 'assets/img/SidebarIcon/ManageSchool/icon-align-syllabus-active.svg';
import SchoolReport from 'assets/img/SidebarIcon/ManageSchool/icon-school-report.svg';
import SchoolReportActive from 'assets/img/SidebarIcon/ManageSchool/icon-school-report-active.svg';
import OtherSettings from 'assets/img/SidebarIcon/ManageSchool/icon-other-settings.svg';
import OtherSettingsActive from 'assets/img/SidebarIcon/ManageSchool/icon-other-settings-active.svg';
import SidebarNavLink from './SidebarNavLink';
import { NavLinkContainer } from './SidebarNavLink.styles';

const SidebarManageSchool = () => {
  const { pathname } = useLocation();
  const isActivePath = (path) => pathname.match(path);

  return (
    <>
      <NavLinkContainer>
        <Link
          to="/manage-school/my-schools"
          className={`nav-link${
            isActivePath('/my-schools') || pathname === '/manage-school'
              ? ' nav-link-active'
              : ''
          }`}
        >
          {isActivePath('/my-schools') || pathname === '/manage-school' ? (
            <>
              <img src={MySchoolsActive} alt="icon"></img>
            </>
          ) : (
            <img src={MySchools} alt="icon"></img>
          )}
          <span>My Schools</span>
        </Link>
      </NavLinkContainer>
      <SidebarNavLink path="/manage-school/create-account">
        {isActivePath('/create-account') ? (
          <img src={CreateAccountActive} alt="icon"></img>
        ) : (
          <img src={CreateAccount} alt="icon"></img>
        )}
        <span>Create Account</span>
      </SidebarNavLink>
      <SidebarNavLink path="/manage-school/renew-account">
        {isActivePath('/renew-account') ? (
          <img src={RenewAccountActive} alt="icon"></img>
        ) : (
          <img src={RenewAccount} alt="icon"></img>
        )}
        <span>Renew Account</span>
      </SidebarNavLink>
      <SidebarNavLink path="/manage-school/align-syllabus">
        {isActivePath('/manage-school/align-syllabus') ? (
          <img src={AlignSyllabusActive} alt="icon"></img>
        ) : (
          <img src={AlignSyllabus} alt="icon"></img>
        )}
        <span>Align Syllabus</span>
      </SidebarNavLink>
      <SidebarNavLink path="/manage-school/school-report">
        {isActivePath('/manage-school/school-report') ? (
          <img src={SchoolReportActive} alt="icon"></img>
        ) : (
          <img src={SchoolReport} alt="icon"></img>
        )}
        <span>School Report</span>
      </SidebarNavLink>
      <SidebarNavLink path="/manage-school/other-settings">
        {isActivePath('/manage-school/other-settings') ? (
          <img src={OtherSettingsActive} alt="icon"></img>
        ) : (
          <img src={OtherSettings} alt="icon"></img>
        )}
        <span>Other Settings</span>
      </SidebarNavLink>
    </>
  );
};

export default SidebarManageSchool;
