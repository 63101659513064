import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RaisePO from 'components/ManageSchool/RaisePO';
import DropdownMenu from 'components/Shared/DropdownMenu';
import Spinner from 'components/Shared/Spinner';
import RenewAccountContainer from './RenewAccount.styles';
import RenewAccountTable from 'components/ManageSchool/RenewAccount/RenewAccountTable';
import {
  getAcademicYears,
  getRenewAccountsSummary,
  setSelectedContract,
  setSelectedSchool,
} from 'store/account-creation/accountCreationSlice';
import { parseTableData } from 'helpers/parseAccountTableData';

const RenewAccount = () => {
  const dispatch = useDispatch();

  const { isLoading, renewAccounts, academicYears } = useSelector(
    (state) => state.accountCreation
  );
  const parsedTableData =
    renewAccounts !== null ? parseTableData(renewAccounts) : [];

  const uniqueAcademicYears = parsedTableData
    .map((contract) => contract.academicYear)
    .filter((year, i, a) => a.indexOf(year) === i)
    .sort((a, b) => b - a);
  const [year, setYear] = useState('Select Year');
  if (academicYears !== null && year === 'Select Year') {
    setYear(academicYears[0]);
  }
  // if (uniqueAcademicYears.length > 0 && year === null) {
  //   setYear(uniqueAcademicYears[0]);
  // }

  const filteredByAcademicYear = parsedTableData.filter(
    (item) => item.academicYear === year
  );

  const handleSelectYearOption = (year) => {
    setYear(year);
  };

  // Useeffects
  useEffect(() => {
    dispatch(getAcademicYears());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getRenewAccountsSummary(year));
  }, [dispatch, year]);
  return (
    <RenewAccountContainer>
      <RaisePO></RaisePO>
      <div className="table-label">
        <p>My customer(s)</p>
        <DropdownMenu
          className="dropdown"
          selectedValue={
            year === null ? 'Select year' : `Year ${year} - ${year + 1}`
          }
          valueKey={null}
          values={academicYears}
          setValue={handleSelectYearOption}
          width="160px"
        />
      </div>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <>
          {filteredByAcademicYear.map((data) => (
            <div key={data.contractId}>
              <RenewAccountTable
                data={data}
                selectedSchool={setSelectedSchool}
                selectedContract={setSelectedContract}
                type="student"
              ></RenewAccountTable>
              <RenewAccountTable
                data={data}
                selectedSchool={setSelectedSchool}
                selectedContract={setSelectedContract}
                type="teacher"
              ></RenewAccountTable>
            </div>
          ))}
        </>
      )}
    </RenewAccountContainer>
  );
};

export default RenewAccount;
