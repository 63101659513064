import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import loginReducer from 'store/login/loginSlice';
import accountCreationReducer from 'store/account-creation/accountCreationSlice';
import dashboardReducer from 'store/dashboard/dashboardSlice';
import affiliateDashboardReducer from 'store/affiliate-dashboard/affiliateDashboardSlice';
import logger from 'redux-logger';

const middleware = [
  /*YOUR CUSTOM MIDDLEWARES HERE*/
  ...getDefaultMiddleware(),
];

if (process.env.NODE_ENV !== 'production') {
  // add logger middleware
  middleware.push(logger);
}

const store = configureStore({
  reducer: {
    login: loginReducer,
    accountCreation: accountCreationReducer,
    dashboard: dashboardReducer,
    affiliateDashboard: affiliateDashboardReducer,
  },
  middleware,
});

export default store;
