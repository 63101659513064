import styled from 'styled-components';

export const MySchoolContainer = styled.div`
  margin-left: 50px;
  margin-top: 50px;
  margin-right: 50px;
  .my-school {
    display: flex;
    p {
      margin: 5px 10px;
    }
  }
  .table-data {
    margin-top: 20px;
    thead {
      tr {
        background: #ffcc68;
        text-align: center;
      }
    }
    tbody {
      tr {
        text-align: center;
      }
      .outstanding-payment {
        color: #ec672c;
      }
      .paid {
        color: #60bb40;
      }
    }
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ffdb98;
    padding: 5px;
    vertical-align: middle;
  }

  .table-bordered {
    tbody {
      tr:nth-child(odd) {
        background-color: #fffcf6;
      }
      tr:nth-child(even) {
        background-color: #ffffff;
      }
    }
  }
  .selected {
    background: #fff0c5;
    border: 2px solid #ffc551;
  }
  .container-checkbox {
    display: flex;
    padding: 2px;
    justify-content: center;
    margin-bottom: 4px;
  }

  //hide the browser's default check-box
  .container-checkbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    margin-top: 4px;
  }

  //create a custom checkbox
  .container-checkbox .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid #9e9e9e;
    background-color: white;
  }

  //on mouse hover
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ffc400;
    border: none;
  }

  //when checkbox is checked
  .container-checkbox input:checked ~ .checkmark {
    background-color: #ef7836;
    border: none;
  }

  //create the checkmark/indicator (hidden when not checked)
  .container-checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  //show the checkmark when checked
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  //style the checkmark/indicator
  .container-checkbox .checkmark:after {
    left: 7px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .check-box {
    position: inherit;
  }
`;
