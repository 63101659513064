import React, { useState, useEffect } from 'react';
import { Spinner, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { PreviewCSVContainer, ResetPassword } from './PreviewCSV.styles';
import { useHistory } from 'react-router-dom';
import PopupModal from 'components/Shared/PopupModal';
import ConfirmationModalBody from 'components/ManageSchool/CreateAccount/ConfirmationModalBody';
import PaginationShared from 'components/Shared/Pagination';
import ValidationComments from 'components/ManageSchool/CreateAccount/ValidationComments';
import {
  bulkValidateStudentCSV,
  bulkValidateTeacherCSV,
  resetCSVValidations,
} from 'store/account-creation/accountCreationSlice';
import { bulkCancelValidations } from 'services/accountCreation';
import {
  checkDuplicateValues,
  checkMissingInvalidValues,
  combineAccountLists,
  checkAPICsvValidation,
} from 'helpers/csvUpload';
import { CSVHeaders } from 'constants/index';

const PreviewCSV = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const renewAccount =
    history.location.pathname ===
      '/manage-school/renew-account/upload-csv/teacher-preview' ||
    history.location.pathname ===
      '/manage-school/renew-account/upload-csv/student-preview';

  const accountType =
    history.location.pathname ===
      '/manage-school/create-account/upload-csv/teacher-preview' ||
    history.location.pathname ===
      '/manage-school/renew-account/upload-csv/teacher-preview'
      ? 'teacher'
      : 'student';
  const {
    studentCSV,
    teacherCSV,
    isCreationLoading,
    isRenewalLoading,
    selectedContractId,
    selectedSchool,
    studentCSVValidations,
    teacherCSVValidations,
    isValidationLoading,
  } = useSelector((state) => state.accountCreation);

  //modal state & handlers
  const [showConfirmation, setShowConfirmation] = useState(false);
  const openConfirmation = () => {
    setShowConfirmation(true);
  };
  const hideConfirmation = () => {
    if (isCreationLoading === false && isRenewalLoading === false) {
      setShowConfirmation(false);
    }
  };
  // Checkbox state
  const [isResetPassword, setIsResetPassword] = useState(false);

  //max number of page
  const maxStudentPages = Math.ceil(studentCSV && studentCSV.length / 10);
  const maxTeacherPages = Math.ceil(teacherCSV && teacherCSV.length / 10);

  //dispaly max number of data
  const perPage = 10;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const studentSlice = studentCSV && studentCSV.slice(offset, offset + perPage);
  const teacherSlice = teacherCSV && teacherCSV.slice(offset, offset + perPage);

  // Callback for page change
  const onPageChange = (number) => {
    const newOffset = (number - 1) * perPage;
    setCurrentPage(number);
    setOffset(newOffset);
  };
  // CSV Validation
  let duplicateValidation, missingValidation, frontendValidation;
  if (accountType === 'student' && studentCSV !== null) {
    duplicateValidation = checkDuplicateValues(studentCSV);
    missingValidation = checkMissingInvalidValues(
      'student',
      studentCSV,
      !renewAccount
    );
    if (
      duplicateValidation.pass === false ||
      missingValidation.pass === false
    ) {
      frontendValidation = false;
    } else {
      frontendValidation = true;
    }
    // TODO: Check if all validations pass & set state
  } else if (accountType === 'teacher' && teacherCSV !== null) {
    duplicateValidation = checkDuplicateValues(teacherCSV);
    missingValidation = checkMissingInvalidValues(
      'teacher',
      teacherCSV,
      !renewAccount
    );
    if (
      duplicateValidation.pass === false ||
      missingValidation.pass === false
    ) {
      frontendValidation = false;
    } else {
      frontendValidation = true;
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetCSVValidations());
    };
  }, [dispatch]);

  const [isValidateButtonDisabled, setIsValidateButtonDisabled] = useState(
    accountType === 'student'
      ? studentCSVValidations !== null || frontendValidation === false
        ? true
        : false
      : teacherCSVValidations !== null || frontendValidation === false
      ? true
      : false
  );
  let isSubmitButtonVisible, flattenedAccountLists;
  if (accountType === 'student') {
    flattenedAccountLists =
      studentCSVValidations !== null
        ? combineAccountLists(studentCSVValidations)
        : null;
    isSubmitButtonVisible =
      studentCSVValidations !== null
        ? checkAPICsvValidation(flattenedAccountLists) === true
          ? true
          : false
        : false;
  } else if (accountType === 'teacher') {
    flattenedAccountLists =
      teacherCSVValidations !== null
        ? combineAccountLists(teacherCSVValidations)
        : null;
    isSubmitButtonVisible =
      teacherCSVValidations !== null
        ? checkAPICsvValidation(flattenedAccountLists) === true
          ? true
          : false
        : false;
  }
  return (
    <PreviewCSVContainer>
      <PopupModal show={showConfirmation} hide={hideConfirmation}>
        <ConfirmationModalBody
          hide={hideConfirmation}
          accountType={accountType}
          isResetPassword={isResetPassword}
        ></ConfirmationModalBody>
      </PopupModal>
      <div className="top-bar">
        <button
          className="cancel-btn"
          onClick={async () => {
            if (studentCSVValidations !== null) {
              await bulkCancelValidations(
                studentCSVValidations.map((val) => val.Contract_File_Id)
              );
            }
            if (teacherCSVValidations !== null) {
              await bulkCancelValidations(
                teacherCSVValidations.map((val) => val.Contract_File_Id)
              );
            }
            history.push(
              renewAccount
                ? '/manage-school/renew-account/upload-csv'
                : '/manage-school/create-account/upload-csv'
            );
          }}
        >
          Cancel
        </button>
        <p>
          {accountType.charAt(0).toUpperCase() + accountType.slice(1)} Accounts
          Preview
        </p>
        {!isSubmitButtonVisible && (
          <button
            className="validate-btn"
            disabled={isValidateButtonDisabled}
            onClick={() => {
              setIsValidateButtonDisabled(true);
              if (accountType === 'student') {
                dispatch(
                  bulkValidateStudentCSV({
                    csvRows: studentCSV,
                    contractId: selectedContractId,
                    isCreation: !renewAccount,
                  })
                );
              } else if (accountType === 'teacher') {
                dispatch(
                  bulkValidateTeacherCSV({
                    csvRows: teacherCSV,
                    contractId: selectedContractId,
                    isCreation: !renewAccount,
                  })
                );
              }
            }}
          >
            {isValidationLoading && <Spinner animation="grow" size="sm" />}
            <span style={{ marginLeft: '0.5rem' }}>Validate</span>
          </button>
        )}
        {isSubmitButtonVisible && (
          <button className="submit-btn" onClick={openConfirmation}>
            Submit
          </button>
        )}
      </div>
      <ResetPassword isRenewAccount={renewAccount}>
        <div className="container-checkbox">
          <input
            type="checkbox"
            className="check-box individual"
            name="check"
            onChange={(e) => {
              if (e.target.checked) {
                setIsResetPassword(true);
              } else {
                setIsResetPassword(false);
              }
            }}
          ></input>
          <span className="checkmark" />
        </div>
        <span>Reset existing accounts' password</span>
      </ResetPassword>
      <div className="preview">
        {renewAccount ? (
          <p>{`${selectedSchool.schoolName} (Renewal)`}</p>
        ) : (
          <p>{selectedSchool.schoolName}</p>
        )}
        <table className="table-responsive-md table table-bordered">
          <thead>
            {accountType === 'student' && (
              <tr>
                <th scope="col">Index</th>
                {renewAccount && <th scope="col">New/Existing User</th>}
                <th scope="col">Name</th>
                <th scope="col">Grade Level</th>
                <th scope="col">Class Name</th>
                <th scope="col">Login ID</th>
                <th scope="col">Password</th>
                <th scope="col">Error</th>
              </tr>
            )}
            {accountType === 'teacher' && (
              <tr>
                <th scope="col">Index</th>
                {renewAccount && <th scope="col">New/Existing User</th>}
                <th scope="col">Name</th>
                <th scope="col">Login ID</th>
                <th scope="col">Password</th>
                <th scope="col">Error</th>
              </tr>
            )}
          </thead>
          <tbody>
            {accountType === 'student' &&
              studentSlice &&
              studentSlice.map((row, index) => (
                <tr key={`${row.login_id}-${index}`}>
                  <td>{index + offset + 1}</td>
                  {renewAccount && <td>{String(row.new_existing_user)}</td>}
                  <td>{String(row.name)}</td>
                  <td>{String(row.level)}</td>
                  <td>{String(row.class_name)}</td>
                  <td>{String(row.login_id)}</td>
                  <td>{String(row.password)}</td>
                  <td className="error-cell">
                    {missingValidation &&
                      missingValidation.missingValues[index + offset].length >
                        0 && (
                        <p>
                          {missingValidation.missingValues[index + offset].map(
                            (missingValue) => (
                              <Badge variant="warning" key={missingValue}>
                                {CSVHeaders[missingValue]}
                              </Badge>
                            )
                          )}{' '}
                          should not be blank.
                        </p>
                      )}
                    {missingValidation &&
                      missingValidation.invalidValues[index + offset].length >
                        0 && (
                        <p>
                          {missingValidation.invalidValues[index + offset].map(
                            (invalidValue) => (
                              <Badge variant="warning" key={invalidValue}>
                                {invalidValue}
                              </Badge>
                            )
                          )}{' '}
                          should be a valid value.
                        </p>
                      )}
                    {duplicateValidation &&
                      duplicateValidation.duplicateRows[index + offset]
                        .isDuplicate && (
                        <p>
                          <Badge variant="warning">Login ID</Badge>
                          {` should not have duplicate value with row ${duplicateValidation.duplicateHashmap[
                            duplicateValidation.duplicateRows[index + offset]
                              .value
                          ]
                            .filter((rowNumber) => rowNumber !== index + offset)
                            .map((index) => index + 1)
                            .join(', ')}`}
                        </p>
                      )}
                    {studentCSVValidations &&
                      flattenedAccountLists[index + offset].Comments !==
                        null && (
                        <ValidationComments
                          comments={
                            flattenedAccountLists[index + offset].Comments
                          }
                        />
                      )}
                  </td>
                </tr>
              ))}
            {accountType === 'teacher' &&
              teacherSlice &&
              teacherSlice.map((row, index) => (
                <tr>
                  <td>{index + offset + 1}</td>
                  {renewAccount && <td>{String(row.new_existing_user)}</td>}
                  <td>{String(row.name)}</td>
                  <td>{String(row.login_id)}</td>
                  <td>{String(row.password)}</td>
                  <td className="error-cell">
                    {missingValidation &&
                      missingValidation.missingValues[index + offset].length >
                        0 && (
                        <p>
                          {missingValidation.missingValues[index + offset].map(
                            (missingValue) => (
                              <Badge variant="warning" key={missingValue}>
                                {CSVHeaders[missingValue]}
                              </Badge>
                            )
                          )}{' '}
                          should not be blank.
                        </p>
                      )}
                    {missingValidation &&
                      missingValidation.invalidValues[index + offset].length >
                        0 && (
                        <p>
                          {missingValidation.invalidValues[index + offset].map(
                            (invalidValue) => (
                              <Badge variant="warning" key={invalidValue}>
                                {invalidValue}
                              </Badge>
                            )
                          )}{' '}
                          should be a valid value.
                        </p>
                      )}
                    {duplicateValidation &&
                      duplicateValidation.duplicateRows[index + offset]
                        .isDuplicate && (
                        <p>
                          <Badge variant="warning">Login ID</Badge>
                          {` should not have duplicate value with row ${duplicateValidation.duplicateHashmap[
                            duplicateValidation.duplicateRows[index + offset]
                              .value
                          ]
                            .filter((rowNumber) => rowNumber !== index + offset)
                            .map((index) => index + 1)
                            .join(', ')}`}
                        </p>
                      )}
                    {teacherCSVValidations &&
                      flattenedAccountLists[index + offset].Comments !==
                        null && (
                        <ValidationComments
                          comments={
                            flattenedAccountLists[index + offset].Comments
                          }
                        />
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <PaginationShared
        maxPage={accountType === 'student' ? maxStudentPages : maxTeacherPages}
        onPageChange={onPageChange}
        onSearchPageChange={onPageChange}
        page={currentPage}
        searchPage={currentPage}
      ></PaginationShared>
    </PreviewCSVContainer>
  );
};

export default PreviewCSV;
