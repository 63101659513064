export const fetchCustomers = () => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/customer/GetMyCustomersAndAuthorizedCustomers`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch customers failed'); // eslint-disable-line
    });
};
