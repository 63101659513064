import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { ConfirmationModalBodyContainer } from './ConfirmationModalBody.styles';
import {
  bulkCreateAccounts,
  bulkRenewAccounts,
  resetStudentCSVFileAndValidations,
  resetTeacherCSVFileAndValidations,
} from 'store/account-creation/accountCreationSlice';

const ConfirmationModalBody = ({ hide, accountType, isResetPassword }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    studentCSVValidations,
    teacherCSVValidations,
    accountCreationResult,
    accountRenewalResult,
    isCreationLoading,
    isRenewalLoading,
    error,
  } = useSelector((state) => state.accountCreation);

  const renewAccount =
    history.location.pathname ===
      '/manage-school/renew-account/upload-csv/student-preview' ||
    history.location.pathname ===
      '/manage-school/renew-account/upload-csv/teacher-preview';

  useEffect(() => {
    if (accountCreationResult === true || accountRenewalResult === true) {
      if (accountType === 'student') {
        dispatch(resetStudentCSVFileAndValidations());
      } else if (accountType === 'teacher') {
        dispatch(resetTeacherCSVFileAndValidations());
      }
      hide();
      history.push(
        renewAccount
          ? '/manage-school/renew-account/upload-csv'
          : '/manage-school/create-account/upload-csv'
      );
    }
  }, [
    history,
    renewAccount,
    accountCreationResult,
    accountRenewalResult,
    accountType,
    dispatch,
    hide,
  ]);
  return (
    <ConfirmationModalBodyContainer>
      <div className="title">
        <h5>
          Account {renewAccount ? 'Renewal' : 'Creation'} Request Submission
        </h5>
      </div>
      <div className="subtitle">
        You are about to upload the {accountType} list account{' '}
        {renewAccount ? 'renewal' : 'creation'}. Please ensure the list
        information are accurate.
      </div>
      {error && <p>{error}</p>}
      <div className="buttons">
        <button
          className="cancel"
          onClick={() => {
            hide();
          }}
          disabled={isCreationLoading || isRenewalLoading}
        >
          Cancel
        </button>
        <button
          className="confirm"
          onClick={() => {
            if (accountType === 'student') {
              const contractFileIDs = studentCSVValidations.map(
                (file) => file.Contract_File_Id
              );
              if (renewAccount === true) {
                dispatch(
                  bulkRenewAccounts({
                    contractFileIds: contractFileIDs,
                    isResetPassword: isResetPassword,
                  })
                );
              } else {
                dispatch(bulkCreateAccounts(contractFileIDs));
              }
            } else if (accountType === 'teacher') {
              const contractFileIDs = teacherCSVValidations.map(
                (file) => file.Contract_File_Id
              );
              if (renewAccount === true) {
                dispatch(
                  bulkRenewAccounts({
                    contractFileIds: contractFileIDs,
                    isResetPassword: isResetPassword,
                  })
                );
              } else {
                dispatch(bulkCreateAccounts(contractFileIDs));
              }
            }
          }}
          disabled={isCreationLoading}
        >
          {(isCreationLoading || isRenewalLoading) && (
            <Spinner animation="grow" size="sm" />
          )}
          <span style={{ marginLeft: '0.5rem' }}>Confirm & Create</span>
        </button>
      </div>
    </ConfirmationModalBodyContainer>
  );
};

export default ConfirmationModalBody;
