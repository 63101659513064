import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import PartnersLogo from 'assets/img/icon-header-partners.svg';
import NavbarCollapse from 'components/Header/Navigations/NavbarCollapse';
import NavProfile from 'components/Header/Navigations/NavProfile';
import { HeaderNav } from './Header.styles';

const Header = () => {
  return (
    <HeaderNav>
      <Link className="navbar-brand" to="/dashboard">
        <Logo></Logo>
        <span className="partners-logo">
          Partners
          <img src={PartnersLogo} alt="partner-icon"></img>
        </span>
      </Link>
      <NavbarCollapse></NavbarCollapse>
      <NavProfile></NavProfile>
    </HeaderNav>
  );
};

export default Header;
