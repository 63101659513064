import { history } from '../Routes';
import store from 'store/index';
import { resetDashboard } from 'store/dashboard/dashboardSlice';
import { resetAccountCreation } from 'store/account-creation/accountCreationSlice';
import { resetLogin } from 'store/login/loginSlice';

export default () => {
  // clear tokens from localStorage
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  store.dispatch(resetLogin());
  store.dispatch(resetDashboard());
  store.dispatch(resetAccountCreation());
  // logout by navigate to login screen
  history.push('/login');
};
