import React from 'react';
import Routes from './Routes';
import './App.css';
import 'assets/fonts/fonts.css';
import { GlobalStyle } from 'assets/styles/globalStyle.style';

function App() {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <Routes />
    </>
  );
}

export default App;
