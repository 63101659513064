// Returns true/false depending on whether csv file meets remaining quota left
export const checkAccountQuota = (remainingQuota, numOfCsvRows) => {
  return numOfCsvRows <= remainingQuota;
};

export const checkCSVHeaders = (accountType, csvData, isCreation) => {
  const firstRow = csvData[0];
  let passValidation = true;
  let headers;
  const validationResult = {
    missingHeaders: [],
    invalidHeaders: [],
  };
  if (accountType === 'student') {
    headers = ['name', 'level', 'class_name', 'login_id', 'password'];
  } else if (accountType === 'teacher') {
    headers = ['name', 'login_id', 'password', 'level'];
  }
  // Check if csv file is for account creation or renewal
  if (!isCreation) {
    headers.unshift('new_existing_user');
  }
  // Check if required headers exist in csv file
  const csvHeaders = Object.keys(firstRow);
  for (const header of headers) {
    if (csvHeaders.includes(header) === false) {
      passValidation = false;
      validationResult.missingHeaders.push(header);
    }
  }
  // Check for invalid headers in csv file
  for (const header of csvHeaders) {
    if (headers.includes(header) === false) {
      passValidation = false;
      validationResult.invalidHeaders.push(header);
    }
  }
  validationResult['pass'] = passValidation;
  return validationResult;
};

export const checkMissingInvalidValues = (accountType, csvData, isCreation) => {
  let passValidation = true;
  let headers;
  const validationResult = {
    missingValues: [],
    invalidValues: [],
  };
  // Required columns
  if (accountType === 'student') {
    headers = ['name', 'level', 'class_name', 'login_id', 'password'];
  } else if (accountType === 'teacher') {
    headers = ['name', 'login_id', 'password', 'level'];
  }
  // Check if csv file is for account creation or renewal
  if (!isCreation) {
    headers.unshift('new_existing_user');
  }

  for (const row of csvData) {
    // Check for missing values
    const missingCols = [];
    for (const col of headers) {
      if (row[col] === null) {
        missingCols.push(col);
        passValidation = false;
      }
    }
    validationResult.missingValues.push(missingCols);
    // Check for invalid values
    const invalidCols = [];
    // Check new/existing user col
    if (!isCreation) {
      if (typeof row.new_existing_user !== 'string') {
        invalidCols.push('New/Existing User');
        passValidation = false;
      } else if (
        row.new_existing_user.toLowerCase() !== 'new' &&
        row.new_existing_user.toLowerCase() !== 'existing'
      ) {
        invalidCols.push('New/Existing User');
        passValidation = false;
      }
    }
    // Check Grade Level
    // TODO: Check value for grade level for teacher csv
    if (typeof row.level === 'string') {
      // Check if string contains only numbers
      const numberConstraint = /^[0-9]+$/;
      if (row.level.match(numberConstraint) === null) {
        invalidCols.push('Level');
        passValidation = false;
      } else {
        const parsedInt = parseInt(row.level, 10);
        if (parsedInt < 1 || parsedInt > 6) {
          invalidCols.push('Level');
          passValidation = false;
        }
      }
    } else if (typeof row.level !== 'number') {
      invalidCols.push('Level');
      passValidation = false;
    } else {
      // Check if number value is between range 1-6
      if (row.level < 1 || row.level > 6) {
        invalidCols.push('Level');
        passValidation = false;
      }
    }
    // Check Login ID
    const constraint = /^[0-9a-zA-Z-_@.]+$/;
    const validateLoginID =
      row.login_id !== null ? row.login_id.toString().match(constraint) : null;
    if (validateLoginID === null) {
      passValidation = false;
      invalidCols.push('Login ID');
    }
    validationResult.invalidValues.push(invalidCols);
  }
  validationResult.pass = passValidation;
  return validationResult;
};

// Check for duplicate rows with same login ID
export const checkDuplicateValues = (csvData) => {
  let passValidation = true;
  const validationResult = {
    duplicateRows: [],
    duplicateHashmap: {},
  };
  const seenHashmap = {};
  const duplicateRows = csvData.map((row, index) => {
    if (seenHashmap.hasOwnProperty(row.login_id)) {
      passValidation = false;
      seenHashmap[row.login_id].push(index);
      return {
        value: row.login_id,
        isDuplicate: true,
      };
    } else {
      seenHashmap[row.login_id] = [index];
      return {
        value: row.login_id,
        isDuplicate: false,
      };
    }
  });
  const duplicateValues = Object.keys(seenHashmap);
  for (const value of duplicateValues) {
    if (seenHashmap[value].length > 1) {
      const firstEncounterIndex = seenHashmap[value][0];
      duplicateRows[firstEncounterIndex].isDuplicate = true;
    }
  }
  validationResult.duplicateRows = duplicateRows;
  validationResult.duplicateHashmap = seenHashmap;
  validationResult.pass = passValidation;
  return validationResult;
};

export const splitCSVRowsIntoBatches = (csvData) => {
  const output = [];
  const batchSize = 100;
  for (let i = 0, j = csvData.length; i < j; i += batchSize) {
    let tempArray = csvData.slice(i, i + batchSize);
    output.push(tempArray);
  }
  return output;
};

export const parseVerifyCSVBody = (csvData, accountType, isCreation) => {
  if (accountType === 'Student') {
    return csvData.map((row) => {
      return {
        IsExistingUser: isCreation
          ? false
          : row.new_existing_user.toLowerCase() === 'new'
          ? false
          : true,
        Name: row.name.toString(),
        LoginId: row.login_id.toString(),
        Password: row.password.toString(),
        GradeLevel: parseInt(row.level, 10),
        Class: row.class_name.toString(),
      };
    });
  } else if (accountType === 'Teacher') {
    return csvData.map((row) => {
      return {
        IsExistingUser: isCreation
          ? false
          : row.new_existing_user.toLowerCase() === 'new'
          ? false
          : true,
        Name: row.name.toString(),
        LoginId: row.login_id.toString(),
        Password: row.password.toString(),
        // TODO: Add grade level
        GradeLevel: parseInt(row.level, 10),
      };
    });
  }
};

// Combine and flatten api response from bulk validation into 1D array
export const combineAccountLists = (apiResult) => {
  const filtered = apiResult
    .filter((result) => result.hasOwnProperty('Accountlist'))
    .map((result) => result.Accountlist);
  const flattened = filtered.flat();
  return flattened;
};

export const checkAPICsvValidation = (apiResult) => {
  const resultWithCommentsIndex = apiResult.findIndex(
    (account) => account.Comments !== null
  );
  if (resultWithCommentsIndex !== -1) {
    return false;
  }
  return true;
};
