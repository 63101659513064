import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MySchoolContainer } from './MySchool.styles';
import DropdownMenu from 'components/Shared/DropdownMenu';
import {
  getCustomersByYear,
  getAcademicYears,
} from 'store/account-creation/accountCreationSlice';
import { formatNumber } from 'helpers/formatNumber';
import Spinner from 'components/Shared/Spinner';
import { ContractStatus, PaymentStatus, AccountType } from 'constants/index';

const setCheckboxes = (check) => {
  const checkboxes = document.getElementsByClassName('individual');
  for (let i = 0; i < checkboxes.length; i++) {
    checkboxes[i].checked = check;
  }
};

const MySchool = () => {
  const dispatch = useDispatch();
  const { isLoading, customers, academicYears } = useSelector(
    (state) => state.accountCreation
  );
  const [year, setYear] = useState(2020);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const customerArray = customers && customers.map((cust) => cust.School_Id);

  useEffect(() => {
    dispatch(getAcademicYears());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomersByYear(year));
  }, [dispatch, year]);

  const tableData =
    customers &&
    customers.map((data) => (
      <tr key={data.School_Id}>
        <td
          className={
            selectedCustomer.includes(data.School_Id) ? 'selected' : null
          }
        >
          <div className="container-checkbox">
            <input
              type="checkbox"
              className="check-box individual"
              name="check"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedCustomer([...selectedCustomer, data.School_Id]);
                } else {
                  setSelectedCustomer(
                    selectedCustomer.filter((item) => item !== data.School_Id)
                  );
                }
              }}
            ></input>
            <span className="checkmark" />
          </div>
        </td>
        <td
          className={
            selectedCustomer.includes(data.School_Id) ? 'selected' : null
          }
        >
          {data.CountryName === null ? '-' : data.CountryName}
        </td>
        <td
          className={
            selectedCustomer.includes(data.School_Id) ? 'selected' : null
          }
        >
          {data.SchoolName}
        </td>
        {data.ContractModels.length > 0 ? (
          data.ContractModels.map((contract) => (
            <td
              className={
                selectedCustomer.includes(data.School_Id) ? 'selected' : null
              }
              key={contract.ContractId}
            >
              {PaymentStatus[contract.PaymentStatus] === 'Not Paid' &&
              contract.ContractDetails.length > 0
                ? formatNumber(
                    'en',
                    contract.ContractDetails.filter(
                      (lineItem) =>
                        AccountType[lineItem.AccountType] === 'Student' &&
                        lineItem.ItemType === 'Account'
                    ).reduce((accumulator, currentValue) => {
                      return accumulator + currentValue.AccountsPurchased;
                    }, 0)
                  )
                : '-'}
            </td>
          ))
        ) : (
          <>
            <td
              className={
                selectedCustomer.includes(data.School_Id) ? 'selected' : null
              }
            >
              -
            </td>
          </>
        )}
        {data.ContractModels.filter(
          (item) => ContractStatus[item.ContractStatus] === 'Renewed'
        ).length > 0 ? (
          <td
            className={
              selectedCustomer.includes(data.School_Id) ? 'selected' : null
            }
          >
            Renewed
          </td>
        ) : (
          <td
            className={
              selectedCustomer.includes(data.School_Id) ? 'selected' : null
            }
          >
            New School
          </td>
        )}
        {data.ContractModels.filter(
          (item) => PaymentStatus[item.PaymentStatus] === 'Not Paid'
        ).length > 0 ? (
          <td
            className={`outstanding-payment ${
              selectedCustomer.includes(data.School_Id) ? 'selected' : null
            }`}
          >
            Oustanding Payment to Koobits
          </td>
        ) : (
          <td
            className={`paid ${
              selectedCustomer.includes(data.School_Id) ? 'selected' : null
            }`}
          >
            Paid
          </td>
        )}
      </tr>
    ));

  return (
    <MySchoolContainer>
      <div className="my-school">
        <p>My Customer</p>
        <DropdownMenu
          className="dropdown"
          selectedValue={`Year ${year} - ${year + 1}`}
          valueKey={null}
          values={academicYears}
          setValue={setYear}
          width="160px"
        ></DropdownMenu>
      </div>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <div className="table-data">
            <table className="table-responsive-md table table-bordered">
              <thead>
                <tr>
                  <th>
                    <div className="container-checkbox">
                      <input
                        type="checkbox"
                        className="check-box"
                        name="check"
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckboxes(true);
                            setSelectedCustomer(customerArray);
                          } else {
                            setCheckboxes(false);
                            setSelectedCustomer([]);
                          }
                        }}
                      ></input>
                      <span className="checkmark" />
                    </div>
                  </th>
                  <th scope="col">Country</th>
                  <th scope="col">School Name</th>
                  <th scope="col">Student Accounts</th>
                  <th scope="col">Customer Status</th>
                  <th scope="col">Payment Status</th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          </div>
        </>
      )}
    </MySchoolContainer>
  );
};

export default MySchool;
