import React from 'react';
import LoginForm from './LoginForm/LoginForm';
import KoobitsLogo from 'assets/img/login-koobits-logo.svg';
import PartnerIcon from 'assets/img/login-partner-icon.svg';
import { LoginSectionContainer } from './LoginSection.styles';

const LoginSection = () => {
  return (
    <LoginSectionContainer>
      <div className="logo">
        <img src={KoobitsLogo} alt="logo"></img>
        <p>Partners</p>
        <img src={PartnerIcon} alt="partner-icon"></img>
      </div>
      <LoginForm></LoginForm>
      <div className="signup-container">
        <p>If you are new, you can apply to be a Koobits partner</p>
        <a
          className="signup-btn"
          href="https://partners.koobits.com/"
          role="button"
        >
          Sign up
        </a>
      </div>
      <footer>KooBits Learning Pte Ltd</footer>
    </LoginSectionContainer>
  );
};

export default LoginSection;
