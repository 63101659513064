import React from 'react';
import Header from 'components/Header/Header';
import DisplayDashboard from './DisplayDashboard';
import { DashboardContainer } from './Dashboard.styles';

const Dashboard = () => {
  return (
    <DashboardContainer>
      <Header></Header>
      <DisplayDashboard></DisplayDashboard>
    </DashboardContainer>
  );
};

export default Dashboard;
