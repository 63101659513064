import React from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import NavItem from './NavItem';
import navs from './navs.json';
import affiliateNav from './affiliate-navs.json';
import { NavbarCollapseContainer } from './NavbarCollapse.styles';

const NavbarCollapse = () => {
  const { isAffiliate } = useSelector((state) => state.login);
  // const handleLogout = () => {};
  return (
    <NavbarCollapseContainer>
      <ul className="navbar-nav mx-lg-auto">
        {isAffiliate
          ? affiliateNav.map((nav, index) => {
              return (
                <NavItem key={String(index)} to={nav.to}>
                  <span>{nav.name}</span>
                </NavItem>
              );
            })
          : navs.map((nav, index) => {
              return (
                <NavItem key={String(index)} to={nav.to}>
                  <span>{nav.name}</span>
                </NavItem>
              );
            })}
      </ul>
    </NavbarCollapseContainer>
  );
};

export default NavbarCollapse;
