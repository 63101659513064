import React from 'react';
import Background from 'assets/img/login-background.svg';
import Decoration from 'assets/img/login-decoration.svg';
import { PartnerNetworkContainer } from './PartnerNetwork.styles';

const PartnerNetwork = () => {
  return (
    <PartnerNetworkContainer>
      <img className="background" src={Background} alt="background"></img>
      <img className="decoration" src={Decoration} alt="background"></img>
      <div className="title">
        <h5>KooBits Learning Partnership Network</h5>
        <p>Bring the best of education to your region</p>
      </div>
      <a
        className="btn learn-more"
        href="https://partners.koobits.com/"
        role="button"
      >
        Learn More
      </a>
    </PartnerNetworkContainer>
  );
};

export default PartnerNetwork;
