import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCustomers } from 'services/dashboard';
import refreshFetchWrapper from 'services/refreshToken';

const initialState = {
  customers: null,
  authorizedSchools: null,
  isLoading: false,
  error: null,
};

export const getCustomers = createAsyncThunk('dashboard/getCustomers', () => {
  return refreshFetchWrapper(fetchCustomers);
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    resetDashboard: (state) => initialState,
  },
  extraReducers: {
    [getCustomers.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getCustomers.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.customers = action.payload.Result.Customers;
      state.authorizedSchools = action.payload.Result.AuthorizedCustomers;
    },
    [getCustomers.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = dashboardSlice;
export const { resetDashboard } = actions;
export default reducer;
