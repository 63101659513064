import React from 'react';
import { Badge } from 'react-bootstrap';

const ValidationComments = ({ comments }) => {
  const hasLoginIDString = comments.includes('LoginId');
  return (
    <p>
      {hasLoginIDString === true && <Badge variant="warning">Login ID</Badge>}
      {hasLoginIDString === true ? comments.replace('LoginId', '') : comments}
    </p>
  );
};

export default ValidationComments;
