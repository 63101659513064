export const primaryButton = `
border: none;
background-color: #ff5b00;
color: white;
outline: none;
cursor: pointer;
&:hover {
    background-color: #f25008
}
`;

export const secondaryButton = `
color: #f25008;
border: 1px solid #f25008;
background-color: white;
outline: none;
cursor: pointer;
&:hover {
    background-color: #fff7f3;
}
`;

export const lightButton = `
color: #727272;
border: 1px solid #c7c7c7;
background-color: white;
outline: none;
cursor: pointer;
&:hover {
    color: #ff5b00;
}
`;
