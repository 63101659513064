import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { formatNumber } from 'helpers/formatNumber';
import CreateAccountTableContainer from './CreateAccountTable.styles';

const CreateAccountTable = ({
  data,
  selectedContract,
  selectedSchool,
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <CreateAccountTableContainer>
      <div className="table-data">
        <div key={data.contractId}>
          <table
            className={`table-responsive-md table table-bordered ${
              type === 'student' ? 'student-table' : 'teacher-table'
            } ${
              type === 'student'
                ? data.studentAccRemaining === 0
                  ? 'completed'
                  : 'add-more'
                : data.teacherAccRemaining === 0
                ? 'completed'
                : 'add-more'
            }`}
          >
            <thead>
              <tr>
                <th scope="col" className="date">
                  Date
                </th>
                <th scope="col" className="country">
                  Country
                </th>
                <th scope="col" className="school-name">
                  School Name
                </th>
                <th scope="col" className="purchased">
                  Purchased {type === 'student' ? 'Stu' : 'Tr'} Accounts
                </th>
                <th scope="col" className="accounts">
                  {type === 'student' ? 'Stu' : 'Teacher'} Accounts
                </th>
                <th scope="col" className="action">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="date">{moment(data.date).format('DD MMM')}</td>
                <td className="country">{data.country}</td>
                <td className="school-name">{data.schoolName}</td>
                <td className="purchased">
                  {formatNumber(
                    'en',
                    type === 'student'
                      ? data.studentPurchased
                      : data.teacherPurchased
                  )}
                </td>
                <td className="accounts">
                  {formatNumber(
                    'en',
                    type === 'student'
                      ? data.studentAccCreated
                      : data.teacherAccCreated
                  )}
                </td>
                <td className="action">
                  <button
                    onClick={() => {
                      history.push('/manage-school/create-account/upload-csv');
                      dispatch(selectedContract(data.contractId));
                      dispatch(selectedSchool(data));
                    }}
                    className={
                      type === 'student'
                        ? data.studentAccRemaining === 0
                          ? 'completed-btn'
                          : undefined
                        : data.teacherAccRemaining === 0
                        ? 'completed-btn'
                        : undefined
                    }
                  >
                    {type === 'student'
                      ? data.studentAccRemaining > 0
                        ? 'Create Students'
                        : 'Completed'
                      : data.teacherAccRemaining > 0
                      ? 'Create Teachers'
                      : 'Completed'}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CreateAccountTableContainer>
  );
};

export default CreateAccountTable;
