import styled from 'styled-components';
import { primaryButton, lightButton } from 'assets/styles/buttons.styles';

export const ConfirmationModalBodyContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin: 20px 0px;
    font-weight: 700;
    color: #ff5b00;
    font-size: 18px;
  }
  .subtitle {
    margin: 0px 10px;
    text-align: left;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px 0px;
    .confirm {
      ${primaryButton}
      border-radius: 2px;
      padding: 5px 20px;
      width: 209px;
    }

    .cancel {
      ${lightButton}
      border-radius: 2px;
      padding: 5px 20px;
      width: 209px;
    }
  }
`;
