import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import Login from 'pages/Login/Login';
import Dashboard from 'pages/Dashboard/Dashboard';
import ManageSchool from 'pages/ManageSchool/ManageSchool';
import AffiliateDashboard from 'pages/AffiliateDashboard/AffiliateDashboard';

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_SUBDOMAIN,
});

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem('access_token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const Routes = () => {
  const { isAffiliate } = useSelector((state) => state.login);
  return (
    <Router history={history}>
      <Switch>
        {isAffiliate ? (
          <AuthenticatedRoute exact path="/" component={AffiliateDashboard} />
        ) : (
          <AuthenticatedRoute exact path="/" component={Dashboard} />
        )}
        <Route exact path="/login" component={Login} />
        {!isAffiliate && (
          <>
            <AuthenticatedRoute path="/dashboard" component={Dashboard} />
            <AuthenticatedRoute
              path="/manage-school"
              component={ManageSchool}
            />
          </>
        )}

        {isAffiliate && (
          <AuthenticatedRoute
            path="/affiliate-dashboard"
            component={AffiliateDashboard}
          />
        )}
      </Switch>
    </Router>
  );
};

export default Routes;
