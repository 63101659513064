import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPromoCode, fetchReferrals } from 'services/affiliateDashboard';
import refreshFetchWrapper from 'services/refreshToken';
import moment from 'moment';

// Helper
const getUniqueMonths = (data, dateKey) => {
  const months = data.map((row) => moment(row[dateKey]).format('MMM - YYYY'));
  const uniqueMonths = Array.from(new Set(months));
  return uniqueMonths;
};

const initialState = {
  isLoading: false,
  promoCode: null,
  referrals: [],
  selectedMonth: null,
  error: null,
};

export const getPromoCode = createAsyncThunk(
  'affiliateDashboard/getPromoCode',
  () => {
    return refreshFetchWrapper(fetchPromoCode);
  }
);

export const getReferrals = createAsyncThunk(
  'affiliateDashboard/getReferrals',
  () => {
    return fetchReferrals();
  }
);

export const affiliateDashboardSlice = createSlice({
  name: 'affiliateDashboard',
  initialState: initialState,
  reducers: {
    setSelectedMonth: (state, action) => {
      state.selectedMonth = action.payload;
    },
  },
  extraReducers: {
    [getPromoCode.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getPromoCode.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.promoCode = action.payload;
    },
    [getPromoCode.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
    [getReferrals.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getReferrals.fulfilled]: (state, action) => {
      const months = getUniqueMonths(
        action.payload.length > 0 ? action.payload[0].SubscribedUsersList : [],
        'PurchaseDate'
      );
      state.isLoading = false;
      state.referrals =
        action.payload.length > 0 ? action.payload[0].SubscribedUsersList : [];
      state.selectedMonth =
        months.length > 0 ? months[months.length - 1] : null;
    },
    [getReferrals.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

const { actions, reducer } = affiliateDashboardSlice;
export const { setSelectedMonth } = actions;
export default reducer;
