import styled from 'styled-components';
import { primaryButton, secondaryButton } from 'assets/styles/buttons.styles';

export const NumberIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 50px 20px 265px;
  background-color: white;
  #student-csv,
  #teacher-csv {
    display: none;
  }
  @media only screen and (max-width: 1366px) {
    padding-left: 190px;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 110px;
  }

  label {
    ${secondaryButton}
    border-radius: 2px;
    padding: 5px 20px;
    margin: 0px 20px;
    font-size: 14px;
    width: 15rem;
    height: 33px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  button {
    ${primaryButton}
    font-size: 14px;
    border-radius: 2px;
    padding: 5px 20px;
    margin: 0px 20px;
    height: 33px;
  }
  .view-btn {
    ${secondaryButton}
  }
  .indicator-label {
    margin-right: 40px;
    font-size: 16px;
    font-weight: bold;
    width: 220px;
    @media only screen and (max-width: 1141px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 1024px) {
      margin-right: 0px;
    }

    .purchased-number {
      font-size: 14px;
      font-weight: normal;
      @media only screen and (max-width: 1141px) {
        font-size: 12px;
      }
    }
  }
  .status-container {
    display: flex;
    align-items: center;
    .total-number {
      width: 200px;
      height: 30px;
      text-align: left;
      margin: 0px 0px;
      font-size: 16px;
      font-weight: bold;
      border: 1px solid #aaaaaa;
      padding: 2px 10px;
      @media only screen and (max-width: 1141px) {
        width: 100px;
      }
    }
    .button-group {
      display: flex;
    }
    .status {
      display: flex;
      flex-direction: column;
      color: #1f975f;
      font-size: 0.875rem;
      p {
        margin-bottom: 0px;
      }
    }
  }
  .remove-btn {
    cursor: pointer;
    margin-top: 5px;
  }
`;
