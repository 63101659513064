import { parseVerifyCSVBody, splitCSVRowsIntoBatches } from 'helpers/csvUpload';
import { AccountType } from 'constants/index';
import pSeries from 'p-series';

// Sequential promises //
export const seriesValidate = ({
  csvRows,
  contractId,
  accountType,
  isCreation,
}) => {
  const batchedCSVData = splitCSVRowsIntoBatches(csvRows);
  const promises = batchedCSVData.map((batch) => () =>
    validateCSVFunction({
      csvRows: batch,
      contractId: contractId,
      accountType: accountType,
      isCreation: isCreation,
    })
  );
  return pSeries(promises).then((res) => res);
};

export const validateCSVFunction = ({
  csvRows,
  contractId,
  accountType,
  isCreation,
}) => {
  const rawBody = {
    Contract_Id: contractId,
    FileType: isCreation ? 1 : 2,
    AccountType: accountType,
    AccountsList: parseVerifyCSVBody(
      csvRows,
      AccountType[accountType],
      isCreation
    ),
  };
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/VerifyCSVBeforeUpload`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Validate CSV failed'); // eslint-disable-line
    });
};

export const seriesCreateAccounts = (contractFileIds) => {
  const promises = contractFileIds.map((contractFileID) => () =>
    createAccountsFunction(contractFileID)
  );
  return pSeries(promises).then((res) => {
    // Check for errors in array of json responses
    if (res.includes(true)) {
      return true;
    } else {
      return false;
    }
  });
};

export const createAccountsFunction = (contractFileId) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/ConfirmAndCreateUser/${contractFileId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        return false;
      } else {
        return true;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Create accounts failed'); // eslint-disable-line
    });
};

export const seriesRenewAccounts = ({ contractFileIds, isResetPassword }) => {
  const promises = contractFileIds.map((contractFileID) => () =>
    renewAccountsFunction(contractFileID, isResetPassword)
  );
  return pSeries(promises).then((res) => {
    // Check for errors in array of json responses
    if (res.includes(true)) {
      return true;
    } else {
      return false;
    }
  });
};

export const renewAccountsFunction = (contractFileID, isResetPassword) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/ConfirmAndRenewUser/${contractFileID}?resetpassword=${isResetPassword}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        return false;
      } else {
        return true;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Renew accounts failed'); // eslint-disable-line
    });
};
/// END ///

export const fetchAccountsByContract = (contractID) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/GetTotalStudentsAndTeachersByContractId/${contractID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ?? 'Fetch account quota by contract ID failed'
      ); // eslint-disable-line
    });
};

export const fetchAccountQuotaByContractIDAccountType = ({
  contractID,
  accountType,
}) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/GetAccountQuota?Contract_Id=${contractID}&AccountType=${accountType}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(
        err?.message ??
          'Fetch account quota by contract ID and account type failed'
      ); // eslint-disable-line
    });
};

export const fetchAcademicYears = () => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/customer/GetAcademicYears`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((result) => {
      return result;
    })
    .then((res) => {
      const jsonResponse = res.json();
      return jsonResponse;
    })
    .catch((err) => {
      throw new Error(
        err?.response?.data?.error_description ?? 'Fetch academic years failed'
      ); // eslint-disable-line
    });
};

export const fetchCustomersByYear = (year) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/customer/GetMyCustomers?Year=${year}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch customers by year failed'); // eslint-disable-line
    });
};

export const fetchNewCustomersByYear = (year) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/customer/GetMyNewCustomers?Year=${year}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch new customers by year failed'); // eslint-disable-line
    });
};

export const fetchRenewAccountsSummary = (year) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/customer/GetRenewAccountsSummary?Year=${year}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Fetch renew accounts summary failed'); // eslint-disable-line
    });
};

export const validateCSV = ({
  csvRows,
  contractId,
  accountType,
  isCreation,
}) => {
  const rawBody = {
    Contract_Id: contractId,
    FileType: isCreation ? 1 : 2,
    AccountType: accountType,
    AccountsList: parseVerifyCSVBody(
      csvRows,
      AccountType[accountType],
      isCreation
    ),
  };
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/VerifyCSVBeforeUpload`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      body: JSON.stringify(rawBody),
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Validate CSV failed'); // eslint-disable-line
    });
};

export const bulkValidateCSV = ({
  csvRows,
  contractId,
  accountType,
  isCreation,
}) => {
  const batchedCSVData = splitCSVRowsIntoBatches(csvRows);
  const promises = batchedCSVData.map((batch) =>
    validateCSV({
      csvRows: batch,
      contractId: contractId,
      accountType: accountType,
      isCreation: isCreation,
    })
  );
  return Promise.allSettled(promises)
    .then((res) => {
      let responses = res.map((response) => {
        return response.value;
      });
      return responses;
    })
    .then((json) => {
      return json;
    });
};

export const cancelValidation = (contractFileID) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/CancelPreviewList?Contract_File_Id=${contractFileID}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Cancel csv preview failed'); // eslint-disable-line
    });
};

export const bulkCancelValidations = (contractFileIds) => {
  const promises = contractFileIds.map((contractFileID) =>
    cancelValidation(contractFileID)
  );
  return Promise.allSettled(promises)
    .then((res) => {
      let responses = res.map((response) => {
        return response.value;
      });
      return responses;
    })
    .then((json) => {
      // Check for errors in array of json responses
      if (json.some((res) => res.IsSuccessful === true)) {
        return json;
      } else {
        throw new Error(`Cancel validation failed for some contract file ids.`);
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Bulk cancel validations failed'); // eslint-disable-line
    });
};

export const bulkCreateAccountsRequest = (contractFileIds) => {
  const promises = contractFileIds.map((contractFileID) =>
    createAccounts(contractFileID)
  );
  return Promise.allSettled(promises)
    .then((res) => {
      let responses = res.map((response) => {
        return response.value;
      });
      return responses;
    })
    .then((json) => {
      // Check for errors in array of json responses
      if (json.includes(true)) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Bulk create accounts failed'); // eslint-disable-line
    });
};

export const createAccounts = (contractFileId) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/ConfirmAndCreateUser/${contractFileId}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return true;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Create accounts failed'); // eslint-disable-line
    });
};

export const renewAccountsRequest = (contractFileID) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/CreateRenewAccounts/ConfirmAndRenewUser/${contractFileID}`,
    {
      method: 'POST',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return true;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Renew accounts failed'); // eslint-disable-line
    });
};

export const bulkRenewAccountsRequest = (contractFileIds) => {
  const promises = contractFileIds.map((contractFileID) =>
    renewAccountsRequest(contractFileID)
  );
  return Promise.allSettled(promises)
    .then((res) => {
      let responses = res.map((response) => {
        return response.value;
      });
      return responses;
    })
    .then((json) => {
      // Check for errors in array of json responses
      if (json.includes(true)) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Bulk renew accounts failed'); // eslint-disable-line
    });
};

export const exportCreatedAccountstoCSV = ({ contractID, accountType }) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/Customer/GetAccountsListCSV?Contract_id=${contractID}&AccountType=${accountType}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.text();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Export created accounts to csv failed'); // eslint-disable-line
    });
};

export const exportCreatedAccountstoJSON = ({
  contractID,
  accountType,
  pageIndex,
  pageSize,
}) => {
  return fetch(
    `${process.env.REACT_APP_API_DOMAIN}/Customer/GetAccountsListJSON?Contract_id=${contractID}&AccountType=${accountType}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    {
      method: 'GET',
      headers: {
        authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (!result.IsSuccessful) {
        throw new Error(result.Message.Message);
      } else {
        return result.Result;
      }
    })
    .catch((err) => {
      throw new Error(err?.message ?? 'Export created accounts to csv failed'); // eslint-disable-line
    });
};
