import styled from 'styled-components';

export const ReferralTableContainer = styled.div`
  .table {
    thead {
      th {
        background-color: #ffcc68;
        border-color: #f3b33a;
      }
    }
    tbody {
      td {
        border-color: #ffdc95;
      }
      tr:nth-child(odd) {
        background-color: #fffcf6;
      }
      tr:nth-child(even) {
        background-color: #ffffff;
      }
      tr {
        .check {
          padding: 2px 5px 2px 2px;
        }
        .country {
          width: 240px;
        }
      }
    }
  }
`;
