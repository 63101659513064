import React, { useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TableDataContainer } from './TableData.styles';
import { formatNumber } from 'helpers/formatNumber';
import { ContractStatus } from 'constants/index';

const TableData = ({ type, data }) => {
  const history = useHistory();

  //check boxes
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const setCheckboxes = (check) => {
    const checkboxes = document.getElementsByClassName(
      type === 'customer-list' ? 'customer' : 'authorized'
    );
    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = check;
    }
  };
  const customerArray = data && data.map((cust) => cust.School_Id);

  return (
    <TableDataContainer>
      <div className="table-label">
        {type === 'customer-list'
          ? `My customer(s) (${moment().year()}-${moment().year() + 1})`
          : 'My authorized school selling list'}
        {type === 'authorized-list' && (
          <button className="authorized-btn">Check School Availability</button>
        )}
      </div>
      <table className=" table-responsive-md table table-bordered">
        <thead>
          <tr>
            <th className="check">
              <div className="container-checkbox">
                <input
                  type="checkbox"
                  className="check-box"
                  name="check"
                  onChange={(event) => {
                    if (event.target.checked) {
                      setCheckboxes(true);
                      setSelectedCustomer(customerArray);
                    } else {
                      setCheckboxes(false);
                      setSelectedCustomer([]);
                    }
                  }}
                ></input>
                <span className="checkmark" />
              </div>
            </th>
            <th scope="col" className="country">
              Country
            </th>
            <th scope="col" className="school-name">
              School Name
            </th>
            <th scope="col">
              {type === 'customer-list' ? 'Status' : 'School Population'}
            </th>
            {type === 'customer-list' && <th scope="col">Action</th>}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr key={item.School_Id}>
                <td
                  className={`check ${
                    selectedCustomer.includes(item.School_Id) && 'selected'
                  }`}
                >
                  <div className="container-checkbox">
                    <input
                      type="checkbox"
                      className={`check-box ${
                        type === 'customer-list' ? 'customer' : 'authorized'
                      }`}
                      name="check"
                      onChange={(event) => {
                        if (event.target.checked) {
                          setSelectedCustomer([
                            ...selectedCustomer,
                            item.School_Id,
                          ]);
                        } else {
                          setSelectedCustomer(
                            selectedCustomer.filter(
                              (customer) => customer !== item.School_Id
                            )
                          );
                        }
                      }}
                    ></input>
                    <span className="checkmark" />
                  </div>
                </td>
                <td
                  className={`country ${
                    selectedCustomer.includes(item.School_Id)
                      ? 'selected'
                      : null
                  }`}
                >
                  {item.CountryName !== null ? item.CountryName : '-'}
                </td>
                <td
                  className={`school-name ${
                    selectedCustomer.includes(item.School_Id)
                      ? 'selected'
                      : null
                  }`}
                >
                  {item.SchoolName}
                </td>
                <td
                  className={
                    selectedCustomer.includes(item.School_Id)
                      ? 'selected'
                      : null
                  }
                >
                  {type === 'customer-list'
                    ? item.ContractModels.filter(
                        (status) =>
                          ContractStatus[status.ContractStatus] === 'Renewed'
                      ).length > 0
                      ? 'Renewed'
                      : 'New School'
                    : formatNumber('en', item.StudentPopulation)}
                </td>
                {type === 'customer-list' && (
                  <td
                    className={
                      selectedCustomer.includes(item.School_Id)
                        ? 'selected'
                        : null
                    }
                  >
                    <button
                      className="action-btn"
                      onClick={() => {
                        history.push('/manage-school');
                      }}
                    >
                      Manage
                    </button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </TableDataContainer>
  );
};

export default TableData;
