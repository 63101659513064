import React, { useEffect } from 'react';
import TableData from 'components/Dashboard/TableData';
import { DisplayContainer } from './DisplayDashboard.style';
import { useSelector, useDispatch } from 'react-redux';
import { getCustomers } from 'store/dashboard/dashboardSlice';
import Spinner from 'components/Shared/Spinner';

const DisplayDashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, customers, authorizedSchools } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);
  return (
    <DisplayContainer>
      <div className="welcome-text">
        Welcome! <span>Reseller</span>
      </div>
      {isLoading && <Spinner></Spinner>}
      {!isLoading && (
        <div className="tables">
          <TableData type="customer-list" data={customers}></TableData>
          <TableData
            type="authorized-list"
            data={authorizedSchools}
          ></TableData>
        </div>
      )}
    </DisplayContainer>
  );
};

export default DisplayDashboard;
