import { AccountType, PaymentStatus } from 'constants/index';

export const parseTableData = (data) => {
  const result = [];
  for (const school of data) {
    const schoolName = school.SchoolName;
    const country = school.CountryName;
    // TODO: Can reseller create accounts for renewed contract types?
    const paidContracts = school.ContractModels.filter(
      (contract) => PaymentStatus[contract.PaymentStatus] === 'Paid'
    );
    // const paidContracts = school.ContractModels;
    if (paidContracts.length > 0) {
      for (const contract of paidContracts) {
        const contractResult = {
          date: contract.StartDate,
          country: country,
          schoolName: schoolName,
          studentPurchased: 0,
          teacherPurchased: 0,
          studentAccCreated: 0,
          teacherAccCreated: 0,
          studentAccRemaining: 0,
          teacherAccRemaining: 0,
          contractId: contract.ContractId,
          academicYear: contract.AcademicYear,
          paidContracts: paidContracts,
        };
        if (contract.ContractDetails.length === 0) {
          result.push(contractResult);
        } else {
          const studentLineItemIndex = contract.ContractDetails.findIndex(
            (lineItem) =>
              AccountType[lineItem.AccountType] === 'Student' &&
              lineItem.ItemType === 'Account'
          );
          const teacherLineItemIndex = contract.ContractDetails.findIndex(
            (lineItem) =>
              AccountType[lineItem.AccountType] === 'Teacher' &&
              lineItem.ItemType === 'Account'
          );
          if (studentLineItemIndex !== -1) {
            contractResult.studentPurchased =
              contract.ContractDetails[studentLineItemIndex].AccountsPurchased;
            contractResult.studentAccCreated =
              contract.ContractDetails[studentLineItemIndex].AccountsConsumed;
            contractResult.studentAccRemaining =
              contract.ContractDetails[studentLineItemIndex].AccountsRemaining;
          }
          if (teacherLineItemIndex !== -1) {
            contractResult.teacherPurchased =
              contract.ContractDetails[teacherLineItemIndex].AccountsPurchased;
            contractResult.teacherAccCreated =
              contract.ContractDetails[teacherLineItemIndex].AccountsConsumed;
            contractResult.teacherAccRemaining =
              contract.ContractDetails[teacherLineItemIndex].AccountsRemaining;
          }
          result.push(contractResult);
        }
      }
    } else {
      continue;
    }
  }
  return result;
};
