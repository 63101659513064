import styled from 'styled-components';

export const NavLinkContainer = styled.div`
  .nav-link {
    color: white;
    padding: 10px 20px;
  }
  .nav-link-active {
    background-color: #FF6E00;
  }
  .nav-link:hover {
    background-color: #FF6E00;
  }
  img {
    margin-right: 8px;
  }
  span {
    @media (max-width: 971px) {
      display: none;
    }
  }
`;
