import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ViewAccountsContainer, TableContainer } from './ViewAccounts.styles';
import { AccountType } from 'constants/index';
import Spinner from 'components/Shared/Spinner';

const ViewAccounts = ({ match }) => {
  const history = useHistory();
  const accountType = AccountType[parseInt(match.params.accountTypeID, 10)];
  const { selectedSchool, isExportCSVLoading, exportResult } = useSelector(
    (state) => state.accountCreation
  );
  const [parsedCsvString, setParsedCsvString] = useState(null);
  let sortedCsvData;
  if (parsedCsvString !== null) {
    if (parsedCsvString.length > 1) {
      sortedCsvData = parsedCsvString.slice().sort(function (row1, row2) {
        // Sort by level
        if (row1.GradeLevel < row2.GradeLevel) return -1;
        else if (row1.GradeLevel > row2.GradeLevel) return 1;

        // Sort by class name
        if (row1.Class.toLowerCase() < row2.Class.toLowerCase()) return -1;
        else if (row1.Class.toLowerCase() > row2.Class.toLowerCase()) return 1;

        // Sort by student/teacher name
        if (row1.Name.toLowerCase() < row2.Name.toLowerCase()) return -1;
        else if (row1.Name.toLowerCase() > row2.Name.toLowerCase()) return 1;

        return 0;
      });
    } else {
      sortedCsvData = [];
    }
  }
  const renewAccount =
    history.location.pathname ===
      '/manage-school/renew-account/view-accounts/1' ||
    history.location.pathname ===
      '/manage-school/renew-account/view-accounts/3';

  useEffect(() => {
    if (exportResult !== null) {
      setParsedCsvString(exportResult);
    }
  }, [exportResult]);
  return (
    <ViewAccountsContainer>
      <div className="top-bar">
        <button
          className="back-btn"
          onClick={() => {
            history.push(
              renewAccount
                ? '/manage-school/renew-account/upload-csv'
                : '/manage-school/create-account/upload-csv'
            );
          }}
        >
          {'<'}
        </button>
        <p>{`Created ${accountType} Accounts`}</p>
      </div>
      {isExportCSVLoading && (
        <div
          style={{
            padding: '110px 50px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Spinner position="relative" top="2rem"></Spinner>
        </div>
      )}
      {!isExportCSVLoading && (
        <TableContainer>
          <p>
            <strong>{selectedSchool.schoolName}</strong>
          </p>
          <table className="table-responsive-md table table-bordered">
            {accountType === 'Student' && parsedCsvString !== null && (
              <>
                <thead>
                  <tr>
                    <th scope="col">Index</th>
                    {renewAccount && <th scope="col">New/Existing User</th>}
                    <th scope="col">Name</th>
                    <th scope="col">Grade Level</th>
                    <th scope="col">Class Name</th>
                    <th scope="col">Login ID</th>
                    <th scope="col">Password</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCsvData.length > 0 &&
                    sortedCsvData.map((row, index) => (
                      <tr key={`${row.LoginId}-${index}`}>
                        <td>{index + 1}</td>
                        {renewAccount && (
                          <td>
                            {row.IsExistingUser === true ? 'Existing' : 'New'}
                          </td>
                        )}
                        <td>{row.Name}</td>
                        <td>{row.GradeLevel}</td>
                        <td>{row.Class}</td>
                        <td>{row.LoginId}</td>
                        <td>{row.Password}</td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
            {accountType === 'Teacher' && parsedCsvString !== null && (
              <>
                <thead>
                  <tr>
                    {renewAccount && <th scope="col">New/Existing User</th>}
                    <th scope="col">Name</th>
                    <th scope="col">Login ID</th>
                    <th scope="col">Password</th>
                    <th scope="col">Grade Level</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCsvData.length > 0 &&
                    sortedCsvData.map((row, index) => (
                      <tr key={`${row.LoginId}-${index}`}>
                        {renewAccount && (
                          <td>
                            {row.IsExistingUser === true ? 'Existing' : 'New'}
                          </td>
                        )}
                        <td>{row.Name}</td>
                        <td>{row.LoginId}</td>
                        <td>{row.Password}</td>
                        <td>{row.GradeLevel}</td>
                      </tr>
                    ))}
                </tbody>
              </>
            )}
          </table>
        </TableContainer>
      )}
    </ViewAccountsContainer>
  );
};

export default ViewAccounts;
