import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreateAccountContainer } from './CreateAccount.styles';
import DropdownMenu from 'components/Shared/DropdownMenu';
import RaisePO from 'components/ManageSchool/RaisePO';
import {
  getNewCustomersByYear,
  getAcademicYears,
  setSelectedContract,
  setSelectedSchool,
} from 'store/account-creation/accountCreationSlice';
import Spinner from 'components/Shared/Spinner';
import { parseTableData } from 'helpers/parseAccountTableData';
import CreateAccountTable from 'components/ManageSchool/CreateAccount/CreateAccountTable';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const { isLoading, newAccounts, academicYears } = useSelector(
    (state) => state.accountCreation
  );
  const [year, setYear] = useState('Select Year');
  if (academicYears !== null && year === 'Select Year') {
    setYear(academicYears[0]);
  }
  const parsedTableData =
    newAccounts !== null ? parseTableData(newAccounts) : [];
  console.log('parsedTableData:', parsedTableData);
  useEffect(() => {
    dispatch(getAcademicYears());
  }, [dispatch]);

  useEffect(() => {
    if (year !== 'Select Year') {
      dispatch(getNewCustomersByYear(year));
    }
  }, [dispatch, year]);
  return (
    <CreateAccountContainer>
      <RaisePO></RaisePO>
      <div className="table-label">
        <p>My customer(s)</p>
        <DropdownMenu
          className="dropdown"
          selectedValue={`Year ${year} - ${year + 1}`}
          valueKey={null}
          values={academicYears}
          setValue={setYear}
          width="160px"
        />
      </div>
      {isLoading && <Spinner></Spinner>}
      {!isLoading && (
        <>
          {parsedTableData.map((data) => (
            <div key={data.contractId}>
              <CreateAccountTable
                data={data}
                selectedContract={setSelectedContract}
                selectedSchool={setSelectedSchool}
                type="student"
              ></CreateAccountTable>
              <CreateAccountTable
                data={data}
                selectedContract={setSelectedContract}
                selectedSchool={setSelectedSchool}
                type="teacher"
              ></CreateAccountTable>
            </div>
          ))}
        </>
      )}
    </CreateAccountContainer>
  );
};

export default CreateAccount;
