import styled from 'styled-components';

export const Page = styled.div`
  padding-top: 50px;
  .welcome-text {
    text-align: left;
    font-size: 18px;
    color: #a4a4a4;
    border-bottom: 1px solid rgba(112, 112, 112, 0.32);
    font-weight: bold;
    padding: 10px 50px;
    background-color: white;
    margin-bottom: 1rem;
    span {
      font-size: 25px;
      color: #545454;
    }
  }
`;

export const Referrals = styled.div`
  margin: 0 1rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  h4 {
    margin-right: 1rem;
  }
`;
