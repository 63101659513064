import styled from 'styled-components';

export const Card = styled.div`
  background: white;
  margin: 0 1rem 2rem 1rem;
  padding: 1rem;
  display: flex;
  .title {
    margin-right: 1rem;
  }

  .promocode {
    display: flex;
    color: #fe5c00;
    button {
      border: none;
      background: ${(props) => (props.hasCopied ? 'silver' : '#fe5c00')};
      font-weight: bold;
      color: white;
      margin-left: 1rem;
      :hover {
        background: ${(props) => (props.hasCopied ? '#9a9a9a' : '#cb4a00')};
      }
    }
  }
`;

export const ContentBankBtn = styled.button`
  position: absolute;
  right: 2rem;
  border: none;
  background: #fe5c00;
  color: white;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  :hover {
    background: #cb4a00;
  }
`;
