import styled from 'styled-components';
import { secondaryButton } from 'assets/styles/buttons.styles';

export const TableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  .action-btn {
    border: none;
    color: #f25008;
    outline: none;
    font-size: 16px;
    padding: 0px;
    background: none;
  }
  .action-btn:hover {
    text-decoration: underline;
  }
  .authorized-btn {
    ${secondaryButton}
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 10px;
    border-color: #ffc7a8;
  }
  .table td,
  .table th {
    vertical-align: middle;
    padding: 2px 10px 2px 30px;
    color: #393939;
  }
  .table th {
    .check {
      padding: 2px;
    }
  }

  .table {
    thead {
      th {
        background-color: #ffcc68;
        border-color: #f3b33a;
      }
      tr {
        .check {
          padding: 2px;
        }
      }
    }
    tbody {
      td {
        border-color: #ffdc95;
      }
      tr:nth-child(odd) {
        background-color: #fffcf6;
      }
      tr:nth-child(even) {
        background-color: #ffffff;
      }
      tr {
        .check {
          padding: 2px 5px 2px 2px;
        }
        .country {
          width: 240px;
        }
      }
    }
  }
  .table-label {
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: bold;
  }

  .container-checkbox {
    display: flex;
    padding: 2px;
    justify-content: center;
    margin-bottom: 4px;
    @media (max-width: 1024px) {
      margin-top: 10px;
      margin-right: 12px;
    }
  }

  .selected {
    background: #fff0c5;
    border: 2px solid #ffc551;
  }
  //hide the browser's default check-box
  .container-checkbox input {
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    margin-top: 4px;
  }

  //create a custom checkbox
  .container-checkbox .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid #9e9e9e;
    background-color: white;
  }

  //on mouse hover
  .container-checkbox:hover input ~ .checkmark {
    background-color: #ffc400;
    border: none;
  }

  //when checkbox is checked
  .container-checkbox input:checked ~ .checkmark {
    background-color: #ef7836;
    border: none;
  }

  //create the checkmark/indicator (hidden when not checked)
  .container-checkbox .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  //show the checkmark when checked
  .container-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  //style the checkmark/indicator
  .container-checkbox .checkmark:after {
    left: 7px;
    top: 1px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .check-box {
    position: inherit;
  }
`;
